import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyAXA9VUGdhBQwTDAJgWDUU4dSN_X8_PNYo",
//   authDomain: "portfolio-tracker-e66eb.firebaseapp.com",
//   projectId: "portfolio-tracker-e66eb",
//   storageBucket: "portfolio-tracker-e66eb.appspot.com",
//   messagingSenderId: "330877588047",
//   appId: "1:330877588047:web:f9477770493844f9ee903a",
//   measurementId: "G-19EYMP6Z43",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDQ95kwEPDACZhcNYRPAmLIzm4KKv7aMrE",
//   authDomain: "portfolio-tracker-b4796.firebaseapp.com",
//   projectId: "portfolio-tracker-b4796",
//   storageBucket: "portfolio-tracker-b4796.appspot.com",
//   messagingSenderId: "810162004059",
//   appId: "1:810162004059:web:f846ff3d6684ecf4bf1f7e",
//   measurementId: "G-Y6S1QDQ1B6",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCU8IAwJFZ4jUg8snd7pkFgMDDK5Pxnwps",
  authDomain: "portfolio-tracker-teszt.firebaseapp.com",
  projectId: "portfolio-tracker-teszt",
  storageBucket: "portfolio-tracker-teszt.appspot.com",
  messagingSenderId: "346935363183",
  appId: "1:346935363183:web:8093c5c46a4cb3859eef9a",
  measurementId: "G-DPF3L8GNNY",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
