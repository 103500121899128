import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./AboutUs.css";
import Frame from "../components/UI/WebsiteFrame";

import rectangle from "../assets/rectangle.png";

function AboutUs() {
  return (    
      <Frame>
          <Helmet>
        <title>Rólunk - Tőzsdézünk - Tőzsdézz bátran! - Portfolio Tracker</title>
        <meta
          name="description"
          content="Az általunk nyújtott információ és szolgáltatás hozzásegíti a befektetőket a céljaik eléréséhez!"
        />
      </Helmet>
        <div className="about-us-container">
          <div className="about-us-hero widget-container">
            <div className="about-us-content">
              <h1>Rólunk</h1>
              <p>
                2015 óta foglalkozunk aktívan tőzsdézéssel. Kevés pénzzel, de
                annál nagyobb lelkesedéssel vágtunk bele az ismeretlenbe!
                Rengeteget olvastunk és tanultunk, hogy a tőzsdézés ne csak egy
                drága hobbi maradjon, hanem valós és jövedelmezőbb alternatívát
                kínáljon a lekötött betétek és állampapírokkal szemben. Ahogy
                haladtunk előre az időben megtakarításaink által folyamatosan
                növeltük a befektetett tőkénket és befektetéseink látványos
                növekedésnek indultak. Mára sikerült több mint a duplájára
                növelnünk a befektetett tőkénket. Az osztalék hozamunk
                évről-évre növekszik, és jelenleg már megközelíti az éves
                minimálbért.
                <br />
                <br />
                Hisszük azt, hogy ezt a tudást hatékonyan át tudjuk adni
                másoknak is! Tudjuk, hogy a tőzsdézés nem való mindenkinek. Nem
                kínálunk könnyű és gyors meggazdagodást! Ellenben aki hajlandó
                vállalni az extra munkát és egy kicsivel több kockázatot, aki
                képes követni a saját maga által felállított stratégiát az
                túlléphet a lekötött betétek és államapapírok által kínált
                szűkös hozamokon és keményebb munkára foghatja a tőkéjét. Ehhez
                az izgalmas ám sokszor egyáltalán nem felhőtlen kalandhoz
                kínálunk úti könyvet a vállalkozó szellemű befektetőknek.
              </p>
            </div>
            <div className="about-us-headline">
              <h2>Kik vagyunk és miért érdemes velünk tartanod?</h2>
            </div>
          </div>
        </div>
        <div className="about-us widget-container">
          <div className="about-us-details-container-text">
            <div className="about-us-details">
              <h2>Nem árulunk zsákbamacskát</h2>
              <p>
                Előfizetőinknek nem árulunk zsákbamacskát. Az előfizetés a könyvben leírtak kiaknázását teszi lehetővé számodra. Megkapod a segédfájlokat, amiket saját műhelyedben igény szerint tovább fejleszthetsz. Helyet biztosítunk a figyelő listád kialakítására és nyomon követésére. Kialakíthatod és nyomon követheted saját stratégiádat. Táblázatok és grafikonok segítségével átláthatóbbá válik portfóliód és befektetési tevékenységed.
                <br />
                <br />
                  Hiszünk a tiszta és átlátható üzletpolitikában ezért nem alkalmazunk apró betűs részt. Minden esetben felhívjuk az előfizetők figyelmét arra, hogy elemzéseink nem minősülnek befektetési ajánlatnak. Nincsenek rejtett költségek, ahogy automatikusan megújuló előfizetés sincs. Ez utóbbit különösen etikátlan üzletpolitikának tartjuk! Amennyiben valaki a könyv megvásárlása után dönt úgy, hogy előfizet a szolgáltatásainkra annak a könyv árát elengedjük az éves díjból. A kedvezmény a könyv végén található kuponkóddal aktiválható.
              </p>
            </div>
          </div>
          <div className="about-us-details-container-img">
            <img src={rectangle} alt="Rectangle" className="about-us-img" />
          </div>
        </div>
        <div className="cta-container">
          <div className="cta widget-container">
            <h3>
            Az általunk nyújtott információ és szolgáltatás hozzásegíti a befektetőket a céljaik eléréséhez!
            </h3>
            <Link to="/regisztracio" className="btn lg-button lg-button-border">
              Regisztrálok
            </Link>
          </div>
        </div>
    </Frame>
  );
}

export default AboutUs;
