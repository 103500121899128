import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSquarePollVertical,
  faCrosshairs,
  faChartLine,
  faChessKnight,
  faFileLines,
  faBookOpen,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import PermissionDenied from "../PermissionDenied";
import Modal from "../Modal";

function DashboardTable(props) {
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [underDevelopment, setUnderDevelopment] = useState(false);

  let iconMapping = {
    chartPie: faChartPie,
    // squarePollVertical: faSquarePollVertical,
    // crosshairs: faCrosshairs,
    // chartLine: faChartLine,
    // chessKnight: faChessKnight,
    // fileLines: faFileLines,
    bookOpen: faBookOpen,
  };

  let deniedIconMapping = {};

  if (props.currentUser.product) {
    iconMapping.fileLines = faFileLines;
  } else {
    deniedIconMapping.fileLines = faFileLines;
  }

  const underDevelopmentMapping = {
    squarePollVertical: faSquarePollVertical,
    crosshairs: faCrosshairs,
    chartLine: faChartLine,
    chessKnight: faChessKnight,
  };

  const selectedIcon = iconMapping[props.img];
  const deniedIcon = deniedIconMapping[props.img];
  const underDevelopmentIcon = underDevelopmentMapping[props.img];

  return (
    <>
      {selectedIcon && (
        <Link to={props.link} className="dashboard-table table">
          <FontAwesomeIcon
            icon={selectedIcon}
            className="dashboard-icon"
            alt={props.title}
          />
          <div className="table-content">
            <span className="table-title">{props.title}</span>
            <section>{props.details}</section>
          </div>
        </Link>
      )}
      {deniedIcon && (
        <Link onClick={setPermissionDenied} className="dashboard-table table">
          
          <FontAwesomeIcon
            icon={deniedIcon}
            className="dashboard-icon"
            alt={props.title}
          />
          <div className="table-content">
            <span className="table-title">{props.title}</span>
            <section>{props.details}</section>
          </div>
          <FontAwesomeIcon icon={faLock} />
        </Link>
      )}
      {underDevelopmentIcon && (
        <Link onClick={setUnderDevelopment} className="dashboard-table table">
          <FontAwesomeIcon
            icon={underDevelopmentIcon}
            className="dashboard-icon"
            alt={props.title}
          />
          <div className="table-content">
            <span className="table-title">{props.title}</span>
            <section>{props.details}</section>
          </div>
        </Link>
      )}
      <PermissionDenied
        onClose={() => setPermissionDenied(false)}
        permissionDenied={permissionDenied}
      />
      <Modal
        onClose={() => setUnderDevelopment(false)}
        isOpenModal={underDevelopment}
        title="Az oldal fejlesztés alatt van"
        description="Weboldalunkat folyamatosan fejlesztjük az újabb funkciókkal, amelyek hamarosan elérhetővé válnak. Kérünk, látogass vissza később. Köszönjük a türelmet!"
        button="Bezár"
      />
    </>
  );
}

export default DashboardTable;
