import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import my_book from "../assets/Tozsdezz-batran_cover.jpg";
import Frame from "../components/UI/Frame";
import useDownloadFile from "../hooks/useDownloadFile";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

import "./BookDownload.css";

function BookDownload() {
  const { getCurrentUser } = useGetCurrentUser();
  const [user, setUser] = useState("");

  const navigate = useNavigate();
  const { handleDownloadClick } = useDownloadFile();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserData = await getCurrentUser();
        setUser(currentUserData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const handleClick = () => {
    try {
      if (user?.product === "book" || user?.product === "portfolioTracker") {
        handleDownloadClick("Tozsdezz batran - Zvolensky Tibor.mobi");
      } else {
        localStorage.setItem("SET_PRODUCT", "book");
        navigate("/kosar");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }
  };

  const handleEpubDownload = () => {
    handleDownloadClick("Tőzsdézz bátran - Zvolensky Tibor.epub");
  };

  return (
    <Frame>
      <Helmet>
        <title>Könyv - Tőzsdézz bátran! - Portfolio Tracker</title>
        <meta
          name="description"
          content="Tudd meg hogyan vágj bele a tőzsdézésbe, hogyan válaszd ki a nagyszerű vállalatokat, hogyan fogd keményebb munkára a megtakarításod."
        />
      </Helmet>
      <div className="container">
        <div className="book-download-container">
          <div className="book-container">
            <h1 className="page-title">Tőzsdézz bátran könyv</h1>
          </div>
          <div className="buy-book-container">
            <img src={my_book} alt="My book" />
            <div className="buy-book-details">
              <span className="buy-book">Mire vársz még? Vágj bele!</span>
              <section>
                <p>Megtanulhatod:</p>
                <ol>
                  <li>Hogyan vágj bele a tőzsdézésbe</li>
                  <li>Hogyan válassz értékpapírszámlát</li>
                  <li>
                    Hogyan kutasd fel és válaszd ki a nagyszerű vállalatokat
                  </li>
                  <li>Hogyan számold ki a vállalat értékét</li>
                  <li>Mikor érdemes vásárolni és eladni</li>
                  <li>Hogyan építsd fel a stratégiádat</li>
                </ol>
                <p id="bonus">BÓNUSZ:</p>
                <ul className="bonus-list">
                  <li>Letöltheted az értékeléshez szükséges alap segédfájlt</li>
                  <li>Letöltheted az elemzésekhez szükséges alap segédfájlt</li>
                </ul>
              </section>
            </div>
            <div className="book button-container">
              <button className="btn gr-button" onClick={handleClick}>
                {user?.product === "book" ||
                user?.product === "portfolioTracker"
                  ? "MOBI"
                  : "MEGVÁSÁROLOM"}
              </button>
              {(user?.product === "book" ||
                user?.product === "portfolioTracker") && (
                <button className="btn gr-button" onClick={handleEpubDownload}>
                  EPUB
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
}

export default BookDownload;
