import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Payment.css";
import simplepay_bankcard_logo from "../../assets/simplepay_bankcard_logo.png";

import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";

function Payment({ billingData, product }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getCurrentUser } = useGetCurrentUser();

  const [currentUserData, setCurrentUserData] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setCurrentUserData(currentUser);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await fetch(`${apiUrl}/api/payment/simplePay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: currentUserData.idToken,
        },
        body: JSON.stringify({
          email: currentUserData.email,
          name: billingData.name,
          postalCode: billingData.postalCode,
          city: billingData.city,
          address: billingData.address,
          houseNumber: billingData.houseNumber,
          product: product,
          price: product === "book" ? 2990 : 15990,
        }),
      });
      const resultJson = await result.json();
      window.location.href = resultJson.data.paymentUrl;
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <div className="login-container">
        <h2>Fizetés</h2>
        <form className="payment-form" onSubmit={handleSubmit}>
          <div className="privancy-policy simplpay-payment">
            <div className="radio-btn">
              <input type="radio" name="payment_methods" checked />
              <label for="payment">Bankkártya</label>
              <img
                src={simplepay_bankcard_logo}
                alt="Simplepay Logo"
                className="simplepay-logo"
              />
            </div>
            <div className="checkbox-btn">
              <input
                type="checkbox"
                id="topping"
                name="privancyPolicy"
                required
              />
              <div>
                Tudomásul veszem, hogy a Zvolensky Tibor E.V. (2112 Veresegyház,
                Etűd utca 8.) adatkezelő által a www.portfoliotracker.hu
                felhasználói adatbázisában tárolt alábbi személyes adataim
                átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó
                részére. Az adatkezelő által továbbított adatok köre az alábbi:
                Vásárló neve, email címe és számlázási címe. Az adatfeldolgozó
                által végzett adatfeldolgozási tevékenység jellege és célja a
                SimplePay Adatkezelési tájékoztatóban, az alábbi linken
                tekinthető meg:{" "}
                <Link
                  to="http://simplepay.hu/vasarlo-aff"
                  className="reg-link"
                  target="_blank"
                >
                  http://simplepay.hu/vasarlo-aff
                </Link>
              </div>
            </div>
          </div>
          <div className="payment-btn">
            <button
              className={`btn gr-button ${!product ? "btn-disabled" : ""}`}
              type="submit"
              disabled={!product}
            >
              Vásárlás
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Payment;
