import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Frame from "../components/UI/Frame";
import "./Templates.css";
import useDownloadFile from "../hooks/useDownloadFile";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faDownload } from "@fortawesome/free-solid-svg-icons";
import PermissionDenied from "../components/PermissionDenied";

function Templates() {
  const { getCurrentUser } = useGetCurrentUser();
  const { handleDownloadClick } = useDownloadFile();
  const [permissionDenied, setPermissionDenied] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserData = await getCurrentUser();
        if (!currentUserData.product) {
          setPermissionDenied(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, []);

  return (
    <Frame>
      <Helmet>
        <title>Sablonok - Tőzsdézz bátran! - Portfolio Tracker</title>
        <meta
          name="description"
          content="Töltsd le a sablonokat melyek segítségedre lesznek a vállalatértékelésnél vagy a versenytársak elemzésénél."
        />
      </Helmet>
      <div className="template">
        <h1 className="page-title">Sablonok</h1>
        <section className="page-content" id="dashboard">
          A sablonok segítségedre vannak a vállalat elemzésénél és az értékének
          meghatározásnál. A sablonokat folyamatosan fejlesztjük és frissítjük.
          A legfrissebb verziók azonnal elérhetők az előfizetők számára.
        </section>
        <div className="dashboard-items">
          <div className="dashboard-table table" id="template">
            <div className="template-table-content">
              <FontAwesomeIcon icon={faFileLines} className="dashboard-icon" />
              <div className="table-content">
                <span className="table-title">
                  Érték
                  <br /> számítás
                </span>
                <section>
                  Az érték számítás sablonnal kiszámíthatod, hogy...
                </section>
              </div>
            </div>
            <button
              className="btn lg-button download"
              onClick={() => handleDownloadClick("Értékszámítás_template.xlsx")}
            >
              Letöltöm
              <FontAwesomeIcon icon={faDownload} className="download-icon" />
            </button>
          </div>
          <div className="dashboard-table table" id="template">
            <div className="template-table-content">
              <FontAwesomeIcon icon={faFileLines} className="dashboard-icon" />
              <div className="table-content">
                <span className="table-title">Beszámoló elemzés</span>
                <section>
                  Az érték számítás sablonnal kiszámíthatod, hogy...
                </section>
              </div>
            </div>
            <button
              className="btn lg-button download"
              onClick={() =>
                handleDownloadClick("Beszámoló_elemzés_template.xlsx")
              }
            >
              Letöltöm
              <FontAwesomeIcon icon={faDownload} className="download-icon" />
            </button>
          </div>
          <div className="dashboard-table table" id="template">
            <div className="template-table-content">
              <FontAwesomeIcon icon={faFileLines} className="dashboard-icon" />
              <div className="table-content">
                <span className="table-title">Versenytárs elemzés</span>
                <section>
                  Az érték számítás sablonnal kiszámíthatod, hogy...
                </section>
              </div>
            </div>
            <button
              className="btn lg-button download"
              onClick={() =>
                handleDownloadClick("Versenytársak_elemzése_template.xlsx")
              }
            >
              Letöltöm
              <FontAwesomeIcon icon={faDownload} className="download-icon" />
            </button>
          </div>
        </div>
      </div>
      {<PermissionDenied permissionDenied={permissionDenied} />}
    </Frame>
  );
}

export default Templates;
