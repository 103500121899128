import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

import "./NewShare.css";
import ComboBox from "../../input/ComboBox";
import Tooltip from "../tooltip/Tooltip";
import DeleteShare from "./DeleteShare";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";

const NewShare = ({ onClose, open, id }) => {
  const { getCurrentUser } = useGetCurrentUser();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let shareId = id;
  const isEditMode = shareId ? true : false;

  const [buySell, setBuySell] = useState("");
  const [trigger, setTrigger] = useState("");
  const [sector, setSector] = useState("");
  const [shareType, setShareType] = useState("");
  const [currency, setCurrency] = useState("");
  const [company, setCompany] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchasedShares, setPurchasedShares] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserData = await getCurrentUser();
        setIdToken(currentUserData.idToken);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, []);

  function setInitialState() {
    setBuySell("");
    setSector("");
    setTrigger("");
    setShareType("");
    setPurchasePrice("");
    setPurchasedShares("");
    setCompany("");
    setCurrency("");
  }

  async function createShare() {
    const currentUser = await getCurrentUser();
    try {
      const response = await fetch(`${apiUrl}/api/portfolio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({
          buySell: buySell,
          trigger: trigger,
          sector: sector,
          shareType: shareType,
          purchasePrice: purchasePrice,
          purchasedShares: purchasedShares,
          createdBy: currentUser.id,
        }),
      });
      await response.json;
      setInitialState();
      onClose();
    } catch (err) {
      console.log("error", err);
    }
  }

  async function updateShare() {
    try {
      const currentUser = await getCurrentUser();
      const response = await fetch(`${apiUrl}/api/portfolio/${shareId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({
          buySell: buySell,
          trigger: trigger,
          sector: sector,
          shareType: shareType,
          purchasePrice: purchasePrice,
          purchasedShares: purchasedShares,
          updatedBy: currentUser.id,
        }),
      });
      await response.json;
      setInitialState();
      onClose();
    } catch (err) {
      console.log("error", err);
    }
  }

  // This effect runs when 'data' changes
  useEffect(() => {
    buySell && trigger && sector && shareType && currency
      ? setDisabled(false)
      : setDisabled(true);
  }, [buySell, trigger, sector, purchasePrice]);

  const submitHandler = (event) => {
    // kell egy state ami változik mentéskor és ezáltal a TableRow újratöltődik
    event.preventDefault();
    if (!disabled) {
      isEditMode ? updateShare() : createShare();
    }
    setChangeData(true);
    navigate("/portfolio");
  };

  const deleteHandler = (event) => {
    event.preventDefault();
    setIsDelete(true);
  };

  useEffect(() => {
    if (isEditMode) {
      const fetchData = async () => {
        const data = await fetch(`${apiUrl}/api/portfolio/${shareId}`, {
          headers: {
            Authorization: idToken,
          },
        });
        const json = await data.json();

        const triggerDetails = await fetch(
          `${apiUrl}/api/portfolio/share/${json[0].trigger_name}`,
          {
            headers: {
              Authorization: idToken,
            },
          }
        );

        const triggerData = await triggerDetails.json();
        setCurrency(triggerData.shareDetails.currency);
        setCompany(triggerData.shareDetails.company);
        setBuySell(json[0].buy_sell);
        setSector(json[0].sector);
        setTrigger(json[0].trigger_name);
        setShareType(json[0].share_type);
        setPurchasePrice(json[0].purchase_price);
        setPurchasedShares(json[0].purchased_shares);
      };
      fetchData().catch(console.error);
    } else {
      setInitialState();
    }
  }, [open]);

  const buySellChangeHandler = (event) => {
    setBuySell(event.target.value);
  };
  const sectorChangeHandler = (event) => {
    setSector(event.target.value);
  };
  const shareTypeChangeHandler = (event) => {
    setShareType(event.target.value);
  };
  const cancelChangeHandler = (isDelete) => {
    setIsDelete(isDelete);
  };

  const triggerChangeHandler = async (event) => {
    const symbol = event.target.value.toUpperCase();
    setTrigger(symbol);

    if (symbol === "") {
      setCurrency("");
      setCompany("");
    } else {
      try {
        const response = await fetch(
          `${apiUrl}/api/portfolio/share/${symbol}`,
          {
            headers: {
              Authorization: idToken,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCurrency(data.shareDetails.currency);
          setCompany(data.shareDetails.company);
          setTriggerError(false);
        } else if (response.status === 404) {
          setTriggerError(true);
          setCurrency("");
          setCompany("");
        }
      } catch {
        setCurrency("");
        setCompany("");
      }
    }
  };

  const handleInputBlur = () => {
    if (trigger === "") {
      setCurrency("");
      setCompany("");
    }
  };

  const buySellArr = {
    label: "buySell",
    title: "Vétel/Eladás",
    items: [null, "buy", "sell"],
  };

  const sectorArr = {
    label: "sector",
    title: "Szektor",
    items: [
      null,
      "Healthcare Sector",
      "Materials Sector",
      "Real Estate Sector",
      "Consumer Staples Sector",
      "Consumer Discretionary Sector",
      "Utilities Sector",
      "Energy Sector",
      "Industrials Sector",
      "Consumer Services Sector",
      "Financials Sector",
      "Technology Sector",
    ],
  };

  const shareTypeArr = {
    label: "shareType",
    title: "Részvény típus",
    items: [null, "Growing", "Dividend payer", "Risk-reward", "Index", "Other"],
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="modal">
        <div className="modal-button">
          <button onClick={onClose}>X</button>
        </div>
        <h3 className="share-title">
          Részvény {isEditMode ? "szerkesztése" : "hozzáadása"}
        </h3>
        <div>{isEditMode}</div>
        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className="input-field">
              <ComboBox
                value={buySellArr}
                changeHandler={buySellChangeHandler}
                required={true}
                selectedValue={buySell}
              />
              <div className="tooltip-container"></div>
            </div>

            <div className="input-field input-container">
              <label htmlFor="name" className="combobox-label">
                Azonosító
              </label>
              <input
                type="text"
                id="trigger"
                className="input-column"
                value={trigger}
                onChange={triggerChangeHandler}
                onBlur={handleInputBlur}
                required
              />
              <div className="tooltip-container">
                <Tooltip
                  text={
                    "A vállalat rövid, egyedi azonosítója. Pl. A Coca-Cola Co triggere KO."
                  }
                ></Tooltip>
              </div>
            </div>
            {triggerError && (
              <div className="not-found-trigger">Trigger nem található</div>
            )}

            <div className="input-field input-container">
              <label
                htmlFor="name"
                className="combobox-label"
                id="company-name"
              >
                Vállalat
              </label>
              <input
                type="text"
                value={company}
                id="company"
                className="input-column"
                disabled
              />
              <div className="tooltip-container"></div>
            </div>

            <div className="input-field">
              <ComboBox
                value={sectorArr}
                changeHandler={sectorChangeHandler}
                required={true}
                selectedValue={sector}
              />
              <div className="tooltip-container"></div>
            </div>

            <div className="input-field">
              <ComboBox
                value={shareTypeArr}
                changeHandler={shareTypeChangeHandler}
                required={true}
                selectedValue={shareType}
              />
              <div className="tooltip-container">
                <Tooltip
                  text={
                    "Válaszd ki, milyen típusú a részvény. GR = Növekedő, árfolyam nyereség miatt vásárolt részvény; Div = Osztalékfizető, osztalék hozam miatt vásárolt részvény; Risk = Kockázati, magas kockázatú részvény magas elvárt hozammal; Ind = Index, Több részvény teljesítményét lekövető értékpapír. pl. BUX Index; Oth – Egyéb típusú értékpapír."
                  }
                ></Tooltip>
              </div>
            </div>

            <div className="input-field input-container">
              <label htmlFor="name" className="combobox-label">
                Vételár
              </label>
              <NumericFormat
                value={purchasePrice}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setPurchasePrice(formattedValue);
                }}
                allowLeadingZeros
                allowNegative={false}
                thousandSeparator=" "
                className="input-column"
                required
              />
              <div className="tooltip-container">
                <Tooltip
                  text={
                    "Egy részvény árfolyama a megvásárlás/eladás időpontjában."
                  }
                ></Tooltip>
              </div>
            </div>

            <div className="input-field input-container">
              <label
                htmlFor="name"
                className="combobox-label"
                id="currency-name"
              >
                Devizanem
              </label>
              <input
                type="text"
                value={currency}
                id="currency-name"
                className="input-column"
                disabled
              />
              <div className="tooltip-container"></div>
            </div>

            <div className="input-field input-container">
              <label htmlFor="name" className="combobox-label">
                Részvény darabszám
              </label>
              <NumericFormat
                value={purchasedShares}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setPurchasedShares(formattedValue);
                }}
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={0}
                thousandSeparator=" "
                className="input-column"
              />
              <div className="tooltip-container">
                <Tooltip
                  text={"A megvásárolt/eladott részvények darabszáma."}
                ></Tooltip>
              </div>
            </div>
          </div>
          <div>{disabled}</div>

          <div className="form-actions">
            <button type="submit" className="btn gr-button">
              {isEditMode ? "Mentés" : "Hozzáadás"}
            </button>
            {isEditMode && (
              <button onClick={deleteHandler} className="delete-button">
                Törlés
              </button>
            )}
            <button onClick={onClose} className="cancel-button">
              Mégsem
            </button>
          </div>
        </form>
      </div>
      <DeleteShare
        open={isDelete}
        id={shareId}
        cancelChangeHandler={cancelChangeHandler}
        onClose={onClose}
      ></DeleteShare>
    </>,
    document.getElementById("overlay-root")
  );
};

export default NewShare;
