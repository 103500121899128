import { useState, useCallback, useContext } from "react";
import { getIdToken, signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { UserContext } from "../../state/userContext";
import { useNavigate } from "react-router-dom";

export const useGetCurrentUser = () => {
  const navigate = useNavigate();
  let logoutTimer = null;
  const userState = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { user, setUser } = userState;

  const getCurrentUser = useCallback(async () => {
    setIsLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!user?.uid) {
      return null;
    }
    try {
      const idToken = await getIdToken(user, true);
      const response = await fetch(`${apiUrl}/api/users/${user.uid}`, {
        headers: {
          Authorization: idToken,
        },
      });

      const userData = await response.json();

      if (!response.ok) {
        throw new Error("Sikertelen végpont hívás");
      }
      console.log("logoutTimer: ", logoutTimer);
      if (logoutTimer) {
        console.log("clearTimeout hívás", new Date());
        clearTimeout(logoutTimer);
      }
      logoutTimer = setTimeout(() => {
        console.log("logout hívás", new Date());
        logout();
      }, 20 * 60 * 1000);

      setIsLoading(false);
      return { ...userData, idToken };
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      throw err;
    }
  }, []);

  const logout = async () => {
    localStorage.removeItem("SET_PRODUCT");
    await signOut(auth);
    setUser(null);
    clearTimeout(logoutTimer);
    navigate("/bejelentkezes");
  };

  return { isLoading, error, getCurrentUser, logout };
};
