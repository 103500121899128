import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { UserProvider } from "./state/userContext";
import { initializeAuthState } from "./components/auth/initializeAuthState";
import { UserContext } from "./state/userContext";

import "./App.css";
import AboutUs from "./pages/AboutUs";
import Aszf from "./pages/Aszf";
import PrivancyPolicy from "./pages/PrivancyPolicy";
import Contact from "./pages/Contact";
import Book from "./pages/Book";
import Dashboard from "./pages/Dashboard";
import HomePage from "./pages/HomePage";
import Portfolio from "./pages/Portfolio";
import PortfolioTracker from "./pages/PortfolioTracker";
import Templates from "./pages/Templates";
import BookDownload from "./pages/BookDownload";
import Login from "./pages/Login";
import RegistrationPage from "./pages/RegistrationPage";
import PasswordChange from "./components/auth/PasswordChange";
import NewPasswordPage from "./pages/NewPasswordPage";
import Cart from "./pages/Cart";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/rolunk", element: <AboutUs /> },
  { path: "/konyv", element: <Book /> },
  { path: "/portfoliotracker", element: <PortfolioTracker /> },
  { path: "/kapcsolat", element: <Contact /> },
  { path: "/aszf", element: <Aszf /> },
  { path: "/adatkezelesi-tajekoztato", element: <PrivancyPolicy /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/portfolio", element: <Portfolio /> },
  { path: "/sablonok", element: <Templates /> },
  { path: "/konyv-vasarlas", element: <BookDownload /> },
  { path: "/bejelentkezes", element: <Login /> },
  { path: "/regisztracio", element: <RegistrationPage /> },
  { path: "/jelszo-megvaltoztatas", element: <PasswordChange /> },
  { path: "/elfelejtett-jelszo", element: <NewPasswordPage /> },
  { path: "/kosar", element: <Cart /> },
]);

// function App() {
//   const { setUser, user } = useContext(UserContext);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchAuthState = async () => {
//       try {
//         const user = await initializeAuthState();
//         setUser(user);
//         console.log("App user: ", user);
//       } catch (error) {
//         console.error("Error initializing auth state:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAuthState();
//   }, []);

//   if (loading) {
//     return <div>Loading Authentication...</div>;
//   }

//   return (
//     <UserProvider>
//       <RouterProvider router={router} />
//     </UserProvider>
//   );
// }

function AppContent() {
  const { setUser } = React.useContext(UserContext); // UserContext elérhető itt, mivel már körbe van csomagolva a Provider-rel.
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthState = async () => {
      try {
        const user = await initializeAuthState();
        setUser(user);
        console.log("App user: ", user);
      } catch (error) {
        console.error("Error initializing auth state:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuthState();
  }, [setUser]);

  if (loading) {
    return <div className="background-frame site-view-height"></div>;
  }

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
}

export default App;
