import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import NewShare from "../components/portfolio/NewShare";
import Table from "../components/portfolio/Table";
import Frame from "../components/UI/Frame";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

import "./Portfolio.css";
import "../App.css";
import PermissionDenied from "../components/PermissionDenied";

function Portfolio() {
  const { getCurrentUser } = useGetCurrentUser();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [shareNumber, setShareNumber] = useState(0);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const changeShareId = (id) => {
    if (window.innerWidth > 768) {
      setIsOpen(true);
      setShareId(id);
    }
  };

  const changeShareNumber = (shareNumber) => {
    setShareNumber(shareNumber);
  };

  useEffect(() => {
    const checkUser = async () => {
      const result = await getCurrentUser();
      setCurrentUser(result);
      if (!Object.keys(result).length) {
        navigate("/bejelentkezes");
      }
    };
    checkUser().catch(console.error);
  }, []);

  return (
    <>
      <Frame>
        <Helmet>
          <title>Portfolió - Tőzsdézz bátran! - Portfolio Tracker</title>
          <meta
            name="description"
            content="Töltsd fel megvásárolt és eladott részvényeidet és kövesd nyomon az árfolyam alakulását."
          />
        </Helmet>
        <div className="portfolio">
          <h1 className="page-title">Portfólióm</h1>
          <section className="page-content" id="dashboard">
            Töltsd fel megvásárolt és eladott részvényeidet és kövesd nyomon az
            árfolyam alakulását. A maximális felhasználói élmény elérésének
            érdekében add meg az összes szükséges adatot.
          </section>
          <button
            className="portfolio-button hide"
            onClick={() => {
              if (
                shareNumber >= 3 &&
                currentUser?.product !== "portfolioTracker"
              ) {
                setPermissionDenied(true);
              } else {
                setShareId(null);
                setIsOpen(true);
              }
            }}
          >
            <span id="add-share-icon">+</span>
            <span id="add-share">Részvény hozzáadása</span>
          </button>
          <Table
            changeShareId={changeShareId}
            open={isOpen}
            changeShareNumber={changeShareNumber}
          ></Table>
        </div>
      </Frame>
      <NewShare onClose={() => setIsOpen(false)} open={isOpen} id={shareId} />
      <PermissionDenied permissionDenied={permissionDenied} />
    </>
  );
}

export default Portfolio;
