import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faUserCircle,
  faBars,
  faXmark,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/portfolio-tracker-logo-white.png";
import "./NavBar.css";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

function NavBar() {
  const { getCurrentUser } = useGetCurrentUser();
  const location = useLocation();
  const [loginRoute, setLoginRoute] = useState("/bejelentkezes");
  const [loadLogin, setLoadLogin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const cartItem = localStorage.getItem("SET_PRODUCT") ? (
    <FontAwesomeIcon icon={faCircle} id="cart-circle" />
  ) : (
    ""
  );

  const fetchUser = async () => {
    try {
      console.log("NavBar getCurrentUser");
      const currentUserData = await getCurrentUser();
      setLoginRoute(currentUserData?.idToken ? "/dashboard" : "/bejelentkezes");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserIconClick = () => {
    setLoadLogin(!loadLogin);
  };

  useEffect(() => {
    fetchUser();
  }, [loadLogin]);

  return (
    <header className="navbar-container">
      <div className="navbar-row">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Portfolio Tracker Logo" className="logo" />
          </Link>
        </div>
        <input type="checkbox" id="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} />
        </label>
        <div className="navbar-content">
          <nav role="navigation" className="nav-menu-main">
            <ul className={isMenuOpen ? "nav-menu-show" : "nav-menu"}>
              <li>
                <Link
                  to="/rolunk"
                  className={
                    location.pathname === "/rolunk"
                      ? "navbar-item active-nav-bar"
                      : "navbar-item"
                  }
                >
                  Rólunk
                </Link>
              </li>
              <li>
                <Link
                  to="/konyv"
                  className={
                    location.pathname === "/konyv"
                      ? "navbar-item active-nav-bar"
                      : "navbar-item"
                  }
                >
                  A könyvről
                </Link>
              </li>
              <li>
                <Link
                  to="/portfoliotracker"
                  className={
                    location.pathname === "/portfoliotracker"
                      ? "navbar-item active-nav-bar"
                      : "navbar-item"
                  }
                >
                  Portfolio Tracker
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="login-icon">
          <div>
            <Link
              to="/kosar"
              id="cart"
              className={location.pathname === "/kosar" ? "active-nav-bar" : ""}
            >
              <FontAwesomeIcon icon={faCartShopping} />
              {cartItem}
            </Link>
          </div>
          <div className="login-tooltip">
            <Link
              to={{ pathname: loginRoute }}
              id="user-login"
              onClick={handleUserIconClick}
              className={
                location.pathname === loginRoute ? "active-nav-bar" : ""
              }
            >
              <FontAwesomeIcon icon={faUserCircle} />
            </Link>
            <div className="bottom">
              <h4>Bejelentkezés / Regisztráció</h4>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
