import React from "react";
import ReactDom from "react-dom";
import { Link, useNavigate } from "react-router-dom";

import "./PermissionDenied.css";

function PermissionDenied({ onClose, permissionDenied }) {
  const navigate = useNavigate();

  if (!permissionDenied) return null;

  const closeModal = async (event) => {
    event.preventDefault();
    if (onClose) {
      onClose();
    } else {
      navigate("/dashboard");
    }
  };

  const setCart = () => {
    localStorage.setItem("SET_PRODUCT", "portfolioTracker");
  };

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="permission-denied-modal">
        <div className="modal-button">
          <button onClick={closeModal}>X</button>
        </div>
        <h2 className="permission-denied-title">
          A tartalom megtekintéséhez előfizetés szükséges
        </h2>
        <div className="permission-denied-text">
        Regisztrált felhasználók számára ez a funkció korlátozottan elérhető. A korlátozás feloldásához előfizetés szükséges.
        </div>
        <div className="permission-denied-btn">
          <Link to="/kosar" onClick={setCart}>
            <button className="btn gr-button">Előfizetek</button>
          </Link>
          <button onClick={closeModal} className="cancel-button">
            Bezár
          </button>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root")
  );
}

export default PermissionDenied;
