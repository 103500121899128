import React, { useEffect } from "react";

import "./WebsiteFrame.css";
import NavBar from "../NavBar";
import Footer from "../Footer";
import CookieBanner from "../CookieBanner";

function Frame(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCookieAccepted = localStorage.getItem("cookieAccepted");

  return (
    <div className="site-view-height">
      <NavBar />
      {props.children}
      <div>{!isCookieAccepted && <CookieBanner />}</div>
      <Footer />
    </div>
  );
}

export default Frame;
