import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Frame from "../components/UI/WebsiteFrame";
import my_book from "../assets/my-book.png";
import book from "../assets/book-pt.png";
import "./Book.css";

function Book() {
  const setCart = () => {
    localStorage.setItem("SET_PRODUCT", "book");
  };
  
  return (
    <Frame>
      <Helmet>
    <title>Könyv vásárlás - Tőzsdézz bátran! - Portfolio Tracker</title>
    <meta
      name="description"
      content="Tudd meg hogyan vágj bele a tőzsdézésbe, hogyan válaszd ki a nagyszerű vállalatokat, hogyan fogd keményebb munkára a megtakarításod."
    />
  </Helmet>
      <div className="my-book-container">
        <div className="my-book-hero widget-container">
          <div className="my-book-img">
            <img src={my_book} alt="My book" className="my-book-cover" />
          </div>
          <div className="my-book-hero-content">
            <span>Mi rettent el attól, hogy tőzsdézz?</span>
            <h1>A könyvről</h1>
            <section>
              Ez a könyv neked szól ha:
              <br />
              <br />
              <li>
                úgy érzed, hogy a munkával megkeresett pénzeddel nem jutsz
                egyről a kettőre
              </li>
              <li>nem elégszel meg a bankbetéted után kapott kamatokkal</li>
              <li>nem elégszel meg az állampapírok hosszú távú hozamával</li>
              <li>keményebb munkára szeretnéd fogni a megtakarításodat</li>
              <li>el szeretnéd érni a pénzügyi függetlenséget</li>
            </section>
          </div>
        </div>
      </div>
      <div className="my-book-content widget-container">
        <h2>Úgy érzed a tőzsdézés csupán szerencsejáték?</h2>
        <p>
          Ha a tőzsde csupán szerencsejáték lenne, akkor sok sztár befektető
          évente megnyeri a jackpotot. Hogyan lehetséges ez?
        </p>
        <h2>Félsz, hogy elveszted az összes pénzed?</h2>
        <p>
          Ha befektetésként tekintünk a tőzsdére, akkor nagyon kicsi annak az
          esélye, hogy elbukd az összes pénzed. A tőzsdézés sem mentes a
          kockázattól, de ha olyan cégekre gondolsz, mint a Coca Cola, Google
          vagy Apple, mennyi esélyt adsz nekik, hogy csődbe mennek?
        </p>
        <h2>Tanulj Balek úrtól</h2>
        <p>
          Balek úr a lehető legrosszabbkor, közvetlenül a 2008-as nagy pénzügyi
          válság előtt vágott bele a tőzsdézésbe. Hét hónap alatt befektetésének
          értéke megfeleződött! Mi lett hősünk sorsa?
        </p>
        <p>
          Mit jelent az, hogy bikapiac meg medvepiac? Mennyire kell
          diverzifikálnom a portfóliómat? Jó móka a tőkeáttét? Mégis honnan
          tudjam mennyit ér egy vállalat és mikor érdemes megvenni a
          részvényeit?
        </p>
        <p>Ezekre és még sok más kérdésre megtalálod a választ a könyvben.</p>
      </div>
      <div className="homepage-book-container" id="my-book-container">
        <div className="homepage-book widget-container">
          <div className="homepage-book-content-img">
            <img src={book} alt="Book" className="homepage-img" />
          </div>
          <div className="homepage-book-content" id="book-content">
            <h2>Mit kapsz egy mozijegy áráért?</h2>
            <section>
              <p>Megtanulhatod:</p>
              <ol>
                <li>Hogyan vágj bele a tőzsdézésbe</li>
                <li>Hogyan válassz értékpapírszámlát</li>
                <li>Hogyan kutasd fel és válaszd ki a nagyszerű vállalatokat</li>
                <li>Hogyan számold ki a vállalat értékét</li>
                <li>Mikor érdemes vásárolni és eladni</li>
                <li>Hogyan építsd fel a stratégiádat</li>
                </ol>
              <p>Azaz, hogyan válj sikeres befektetővé!</p>
              <p id="bonus">BÓNUSZ:</p>
              <ul className="book-bonus">
                <li>Letöltheted az értékeléshez szükséges alap segédfájlt</li>
                <li>Letöltheted az elemzésekhez szükséges alap segédfájlt</li>
              </ul>
            </section>
            <Link to="/kosar" onClick={setCart} className="btn lg-button">
              Letöltöm
            </Link>
          </div>
        </div>
      </div>
    </Frame>
  );
}

export default Book;
