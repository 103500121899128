import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Frame from "../components/UI/WebsiteFrame";
import "./Cart.css";
import change from "../assets/change.png";
import CartAuthentication from "../components/cart/CartAuthentication";
import PaymentResult from "../components/cart/PaymentResult";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

function Cart() {
  const { getCurrentUser } = useGetCurrentUser();
  const [product, setProduct] = useState(localStorage.getItem("SET_PRODUCT"));
  const [paymentResultText, setPaymentResultText] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [paymentResult, setPaymentResult] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    localStorage.setItem("SET_PRODUCT", product);
  }, [product]);

  const fetchUser = async (decodedResult) => {
    try {
      const currentUser = await getCurrentUser();
      const result = await fetch(`${apiUrl}/api/payment/simplePayResult`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: currentUser.idToken,
        },
        body: JSON.stringify({
          responseCode: decodedResult.r,
          status: decodedResult.e,
          transactionId: decodedResult.t,
          orderRef: decodedResult.o,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let encodedData = window.location.search;
    if (encodedData) {
      let rPart = encodedData.match(/r=([^&]*)/)[1];
      let decodedR = JSON.parse(
        atob(decodeURIComponent(rPart.replace(/%3D/g, "=")))
      );
      setPaymentResultText(decodedR.e);
      setTransactionId(decodedR.t);
      setPaymentResult(true);
      fetchUser(decodedR);
    }
  }, []);

  // useEffect(() => {
  //   const processUserData = async () => {
  //     let encodedData = window.location.search;
  //     if (encodedData) {
  //       try {
  //         console.log("processUserData start");

  //         // Részadatok kinyerése és dekódolása
  //         let rPart = encodedData.match(/r=([^&]*)/)[1];
  //         let decodedR = JSON.parse(
  //           atob(decodeURIComponent(rPart.replace(/%3D/g, "=")))
  //         );

  //         console.log("Decoded result:", decodedR);

  //         // Állapotok frissítése
  //         setPaymentResultText(decodedR.e);
  //         setTransactionId(decodedR.t);
  //         setPaymentResult(true);

  //         // Felhasználó lekérdezése
  //         const currentUser = await memoizedGetCurrentUser();
  //         console.log("Current user after getCurrentUser:", currentUser);

  //         // Felhasználó POST kérése
  //         if (currentUser) {
  //           await fetchUser(decodedR);
  //         } else {
  //           console.error("CurrentUser is undefined or null.");
  //         }

  //         console.log("processUserData end");
  //       } catch (error) {
  //         console.error("Error processing user data:", error);
  //       }
  //     }
  //   };

  //   processUserData();
  // }, [memoizedGetCurrentUser]);

  // const fetchUser = async (decodedResult) => {
  //   try {
  //     console.log("fetchUser start");
  //     const currentUser = await memoizedGetCurrentUser();
  //     console.log("currentUser in fetchUser", currentUser);

  //     const result = await fetch(`${apiUrl}/api/payment/simplePayResult`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: currentUser.idToken,
  //       },
  //       body: JSON.stringify({
  //         responseCode: decodedResult.r,
  //         status: decodedResult.e,
  //         transactionId: decodedResult.t,
  //         orderRef: decodedResult.o,
  //       }),
  //     });

  //     console.log("fetchUser end", result);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleBook = () => {
    setProduct("book");
  };

  const handlePortfolioTracker = () => {
    setProduct("portfolioTracker");
  };

  const handleRemoveSelectedItem = () => {
    setProduct("");
  };

  return (
    <Frame>
      <Helmet>
        <title>Kosár - Könyv, e-könyv, előfizetés - Portfolio Tracker</title>
        <meta
          name="description"
          content="Tudd meg hogyan vágj bele a tőzsdézésbe, hogyan válaszd ki a nagyszerű vállalatokat, hogyan fogd keményebb munkára a megtakarításod."
        />
      </Helmet>
      <div className="background-frame">
        <div className="cart-frame">
          <div className="cart-container">
            <h1>Kosár</h1>
            {product && (
              <div className="sum-container">
                <table className="sum-table">
                  <thead>
                    <tr className="sum-table-header">
                      <th>Temék</th>
                      <th>Ár</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="sum-table-product">
                      <td>
                        <h4 className="product-name">
                          {product === "portfolioTracker"
                            ? "Portfolio Tracker"
                            : "Tőzsdézz Bátran"}
                        </h4>
                        <span>
                          {product === "portfolioTracker"
                            ? "1 éves Potrfolió Tracker előfizetés és Tőzsdézz Bátran könyv"
                            : "Tőzsdézz Bátran könyv című e-könyv"}
                        </span>
                      </td>
                      <td className="sum-table-price">
                        {product === "portfolioTracker"
                          ? "15.990 Ft"
                          : "2.990 Ft"}
                      </td>
                      <td>
                        <button
                          onClick={handleRemoveSelectedItem}
                          className="close-btn"
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="sum-price">
                  Összesen:{" "}
                  <span>
                    {product === "portfolioTracker" ? "15.990 Ft" : "2.990 Ft"}
                  </span>
                </div>
              </div>
            )}
            {!product && <span className="empty-cart">A kosarad üres.</span>}
            {(!product || product === "book") && (
              <div className="cart-items-container">
                <h3>{product === "book" ? "Jobban megéri:" : "Add hozzá:"}</h3>
                <div className="cart-items">
                  <div className="items">
                    <h3>Portfolió Tracker</h3>
                    <span>
                      1 éves Portfolió Tracker előfizetés és Tőzsdézz Bátran
                      e-könyv
                    </span>
                  </div>
                  <span className="item-price">15.990 Ft</span>
                  <button onClick={handlePortfolioTracker} className="plus-btn">
                    {product === "book" ? (
                      <img src={change} alt="Change" />
                    ) : (
                      "+"
                    )}
                  </button>
                </div>
                {(product === "portfolioTracker" || !product) && (
                  <div className="cart-items">
                    <div className="items">
                      <h3>Tőzsdézz Bátran</h3>
                      <span>Tőzsdézz Bátran című e-könyv</span>
                    </div>
                    <span className="item-price">2.990 Ft</span>
                    <button onClick={handleBook} className="plus-btn">
                      +
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="cart-login-container">
            <CartAuthentication product={product}></CartAuthentication>
          </div>
        </div>
      </div>
      <PaymentResult
        onClose={() => setPaymentResult(false)}
        paymentResult={paymentResult}
        paymentResultText={paymentResultText}
        transactionId={transactionId}
      />
    </Frame>
  );
}

export default Cart;
