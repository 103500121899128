import React from "react";
import { Helmet } from "react-helmet";
import "./Login.css";
import Frame from "../components/UI/WebsiteFrame";
import Registration from "../components/auth/Registration";

function RegistrationPage() {
  return (
    <Frame>
            <Helmet>
        <title>Regisztráció - Tőzsdézz bátran! - Portfolio Tracker</title>
        <meta
          name="description"
          content="Regisztrálj és próbáld ki ingyen: Portfólió menedzsment, Figyelő lista, Vállalat elemzések, Stratégia, Statisztika, Értékelő sablonok."
        />
      </Helmet>
      <div className="background-frame">
        <div className="login-frame">
          <div className="login-background-container">
            <Registration></Registration>
          </div>
          <div className="login-background registry"></div>
        </div>
      </div>
    </Frame>
  );
}

export default RegistrationPage;
