import React, { useState, useEffect } from "react";
import TableRow from "./TableRow";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";
import order from "../../assets/order.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import "./Table.css";

const Table = ({ changeShareId, open, changeShareNumber }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getCurrentUser } = useGetCurrentUser();
  const [isOpenColumn, setIsOpenColumn] = useState(false); // Az oszlopok sorrendjét tároló változó
  const [isOpenRow, setIsOpenRow] = useState({ trigger: "", isOpen: false }); // Az alsorok állapotát tároló változó
  const [parameterValue, setParameterValue] = useState(open);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [shares, setShares] = useState([]);
  const sortedItems = shares;
  let currentUser;

  useEffect(() => {
    let ignore = false;
    async function fetchProduct() {
      try {
        currentUser = await getCurrentUser();
        const response = await fetch(
          `${apiUrl}/api/portfolio/user/${currentUser.id}`,
          {
            headers: {
              Authorization: currentUser.idToken,
            },
          }
        );

        if (!response.ok) {
          setShares([]);
        } else {
          const json = await response.json();
          changeShareNumber(
            json.reduce((osszeg, elem) => osszeg + elem.details.length, 0)
          );
          if (!ignore) setShares(json);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setShares([]);
      }
    }

    fetchProduct();
    return () => {
      ignore = true;
    };
  }, [open]);

  function compare(a, b) {
    if (sortConfig) {
      if (sortConfig.direction === "ascending") {
        return a[sortConfig.key] - b[sortConfig.key];
      }
      if (sortConfig.direction === "descending") {
        return b[sortConfig.key] - a[sortConfig.key];
      }
      return 0;
    }
  }

  if (sortedItems.length > 0) {
    sortedItems.sort(compare);
  }

  function requestSort(key) {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  }

  // Az oszlopok felfedését kezelő függvény
  const handleToggle = () => {
    setIsOpenColumn(!isOpenColumn);
  };

  useEffect(() => {
    setParameterValue(open);
  }, [open]);

  return (
    <>
      <div className="portfolio-table-container">
        <table className="portfolio-table">
          <thead>
            <tr>
              <th>
                Azonosító
                <div className="trigger table-hide">
                  <span
                    onClick={handleToggle}
                    style={{ cursor: "pointer" }}
                    className="open"
                  >
                    {isOpenColumn ? "-" : "+"}
                  </span>
                </div>
              </th>
              {isOpenColumn && <th>Vállalat</th>}
              {isOpenColumn && <th>Szektor</th>}
              {isOpenColumn && <th>Részvény típus</th>}
              <th>Aktuális árfolyam</th>
              <th className="table-hide">Vételár</th>
              <th className="table-hide">Devizanem</th>
              <th className="table-hide">Részvény darabszám</th>
              <th className="order-column portfolio-column table-hide">
                <span>Befektetett összeg</span>
                <FontAwesomeIcon
                  icon={faSort}
                  className="sort"
                  alt="Order"
                  style={{ cursor: "pointer" }}
                  onClick={() => requestSort("investedAmount")}
                />
              </th>
              <th className="order-column portfolio-column table-hide">
                <span>Befektetés jelenértéke</span>
                <FontAwesomeIcon
                  icon={faSort}
                  alt="Order"
                  className="sort"
                  style={{ cursor: "pointer" }}
                  onClick={() => requestSort("currentValue")}
                />
              </th>
              <th className="order-column portfolio-column">
                <span>Egyenleg</span>
                <FontAwesomeIcon
                  icon={faSort}
                  alt="Order"
                  className="sort"
                  style={{ cursor: "pointer" }}
                  onClick={() => requestSort("balance")}
                />
              </th>
              <th className="table-hide">Egyenleg %</th>
              <th className="table-hide">Éves osztalék</th>
              <th>Saját osztalékráta</th>
              <th className="table-hide">Jelenlegi osztalékráta</th>
              <th>Vállalatelemzés pontszáma</th>
              <th className="table-hide">Feltöltés dátuma</th>
            </tr>
          </thead>
          <tbody>
            {sortedItems.length > 0 &&
              sortedItems.map((row, index) => (
                <TableRow
                  key={index}
                  data={row}
                  isColumnVisible={isOpenColumn}
                  changeShareId={changeShareId}
                  isOpenRow={isOpenRow}
                  setIsOpenRow={setIsOpenRow}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
