import React from "react";

function ComboBox(props) {
  const requiredValue = props.required ? "required" : "";
  return (
    <div className="combobox-container">
      <label htmlFor={props.value.label} className="combobox-label">
        {props.value.title}
      </label>
      <select
        name={props.value.label}
        id={props.value.label}
        onChange={props.changeHandler}
        value={props.selectedValue}
        required
        // required={props.required}
        className="combobox-select"
      >
        {props.value.items.map((item) => (
          <option value={item} key={item} className="combobox-option">
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ComboBox;
