import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import my_book from "../assets/my-book.png";
import mobile from "../assets/mobile.png";
import Tables from "../components/Tables";
import book from "../assets/book-pt.png";
import Frame from "../components/UI/WebsiteFrame";

import "./HomePage.css";

function HomePage() {
  const setBook = () => {
    localStorage.setItem("SET_PRODUCT", "book");
  };

  const setPortfolioTracker = () => {
    localStorage.setItem("SET_PRODUCT", "portfolioTracker");
  };
  return (
    <Frame>
          <Helmet>
    <title>Főoldal - Tőzsdézz bátran! - Portfolio Tracker</title>
    <meta
      name="description"
      content="Kezeld a portfóliód profiként. Itt megtalálsz mindent, amire szükséged van."
    />
  </Helmet>
      <div className="homepage-container">
        <div className="homepage-hero widget-container">
          <div className="homepage-images">
            <img src={my_book} alt="My book" className="my-book" />
            <img src={mobile} alt="Mobil" className="mobile" />
          </div>
          <div className="homepage-hero-text">
            <span>Kezeld a portfóliód profiként</span>
            <h1>
              Itt megtalálsz mindent,<br></br> amire szükséged van
            </h1>
            <section>
              Portfólió nyilvántartás, figyelőlista vezetése, stratégia
              alkotás, portfólió statisztika, elemzések, inspiráció, árazást
              támogató sablonok, modellek
            </section>
          </div>
        </div>
      </div>
      <div className="homepage-portfolio widget-container">
        <div className="homepage-content">
          <h2>Válj Te is sikeres befektetővé</h2>
          <p>
            Alkoss stratégiát, elemezd a vállalatokat, versenytársakat, állítsd
            össze a saját portfóliód és figyelőlistádat. Meríts ötleteket a
            feltöltött vállalat elemzésekből.
          </p>
        </div>
        <div className="all-table">
          <Tables color="table-shadow" title="table-title" />
        </div>
      </div>
      <div className="homepage-sale-container">
        <div className="homepage-sale widget-container">
          <div className="homepage-sale-content">
            <div className="sale-title">
              <h3>Tőzsdézz bátran</h3>
              <p>e-könyv</p>
              <h4>2.990 Ft</h4>
            </div>
            <section>
              <p>Mit kapsz egy mozijegy áráért? Megtanulhatod:</p>
              <ol className="list">
                <li>Hogyan vágj bele a tőzsdézésbe</li>
                <li>Hogyan válassz értékpapírszámlát</li>
                <li>
                  Hogyan kutasd fel és válaszd ki a nagyszerű vállalatokat
                </li>
                <li>Hogyan számold ki a vállalat értékét</li>
                <li>Mikor érdemes vásárolni és eladni</li>
                <li>Hogyan építsd fel a stratégiádat</li>
              </ol>
              <p id="bonus">BÓNUSZ:</p>
              <ul className="bonus-list">
                <li>Letöltheted az értékeléshez szükséges alap segédfájlt</li>
                <li>Letöltheted az elemzésekhez szükséges alap segédfájlt</li>
              </ul>
            </section>
            <div className="sale-btn-container">
              <Link to="/kosar" onClick={setBook}>
                <button className="btn lg-button sale-button">
                  Kérem az e-könyvet
                </button>
              </Link>
            </div>
            <div className="book-promo">
                <p>A könyvet fizikai formában itt tudod megvásárolni: <Link to="https://tozsdezzbatran.hu/" target="_blank">Tőzsdézzbátran.hu</Link></p>
            </div>
          </div>
          <div className="homepage-sale-content">
            <div className="sale-title">
              <h3>Portfolio Tracker</h3>
              <p>előfizetés</p>
              <h4>15.990 Ft/év</h4>
            </div>
            <section>
              <p>Mit kapsz havi egy kávé áráért?</p>
              <ol className="list">
                <li>Hozzáférsz több mint 100 vállalat elemzéséhez</li>
                <li>Profi portfólió kezelő eszközt</li>
                <li>Stratégia alkotó eszközt</li>
                <li>Vizuális elemzéstámogató eszközt</li>
                <li>Kialakíthatod a befektetést támogató figyelőlistádat</li>
              </ol>
              <p id="bonus">BÓNUSZ:</p>
              <ul className="bonus-list">
                <li>Letöltheted a könyvet elektronikus formában</li>
                <li>Letöltheted az értékeléshez szükséges segédfájlokat</li>
                <li>Letöltheted az elemzésekhez szükséges segédfájlokat</li>
              </ul>
            </section>
            <div className="sale-btn-container">
              <Link to="/kosar" onClick={setPortfolioTracker}>
                <button className="btn lg-button sale-button">
                  Előfizetek
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
}

export default HomePage;
