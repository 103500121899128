import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-row">
        <div className="copyright-container">
          © {new Date().getFullYear()} PortfolioTracker - Minden jog fenntartva.
        </div>
        <div className="footer-content">
          <nav role="navigation" className="nav-menu-footer-container">
            <ul className="nav-menu-footer">
              <li>
                <Link to="/aszf" className="navbar-item-footer">
                  Általános Szerződési Feltételek
                </Link>
              </li>
              <li>
                <Link to="/adatkezelesi-tajekoztato" className="navbar-item-footer">
                  Adatkezelési Tájékoztató
                </Link>
              </li>
              <li>
                <Link to="/kapcsolat" className="navbar-item-footer">
                  Kapcsolat
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Footer;
