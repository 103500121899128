import React, { useState } from "react";
import tooltip from "../../assets/tooltip.png";
import "./Tooltip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

function Tooltip({ text }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      //   className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <FontAwesomeIcon icon={faCircleQuestion} alt="Tooltip" className="tooltip-icon" />
      {isVisible && <div className="tooltip">{text}</div>}
    </div>
  );
}

export default Tooltip;
