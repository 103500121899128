import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { auth } from "../../config/firebase-config";
import { sendPasswordResetEmail } from "firebase/auth";

import "./UserLogin.css";
import Modal from "../Modal";

function NewPassword() {
  const [wrongEmail, setWrongEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmation, setConfirmation] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setWrongEmail(false);
      setConfirmation(true);
    } catch (error) {
      setWrongEmail(true);
    }
  };

  return (
    <>
    <Helmet>
   <title>Elfelejtett jelszó - Tőzsdézz bátran! - Portfolio Tracker</title>
   <meta name="description"
    content="Új jelszó igénylése egyszerűen, gyorsan és biztonságosan."/>
</Helmet>
      <div className="login-container forget-password">
        <h1>
          Elfelejtett
          <br /> jelszó
        </h1>
        <form onSubmit={handleSubmit} className="login-form">
          <label htmlFor="email" className="input-label">
            Email cím
          </label>
          <input
            type="text"
            id="email"
            className="input-column reg"
            value={email}
            onChange={handleEmailChange}
          />
          {wrongEmail && <span className="invalid">Nem létező email cím</span>}
          <div className="new-password-btn">
            <button className="btn gr-button">Küldés</button>
          </div>
        </form>
      </div>
      <Modal
        onClose={() => setConfirmation(false)}
        isOpenModal={confirmation}
        title="Siker"
        description="Jelszavad megváltoztatásához az emailt elküldtük."
        button="RENDBEN"
      />
    </>
  );
}

export default NewPassword;
