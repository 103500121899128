import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <div>
      <Helmet>
        <title>Kapcsolat - Tőzsdézz bátran! - Portfolio Tracker</title>
        <meta
          name="description"
          content="Ha kérdésed, vagy észrevételed van keress minket bátran!"
        />
      </Helmet>
      <div className="container dark_bg">
        <NavBar />
        <div className="contact-container widget-container">
          <div className="contact-content-container">
            <div className="contact-headline"></div>
            <div className="contact-content">
              <h1 className="contact-title">Kapcsolat</h1>
            </div>
          </div>
          <div className="contact-content-container">
            <div className="contact-headline envelope">
            <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="contact-content">
              <p>
              Ha kérdésed, vagy észrevételed van keress minket bátran!
              </p>
              <p>
              A Portfoliotracker csapata szívesen fogadja a megkeresésedet.
              </p>
              <p>
                <Mailto email="info@portfoliotracker.hu">info@portfoliotracker.hu</Mailto>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;