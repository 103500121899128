import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSquarePollVertical,
  faCrosshairs,
  faChartLine,
  faChessKnight,
  faCartShopping,
  faRightFromBracket,
  faCircle,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/portfolio-tracker-logo-white.png";
import "./DashboardMenu.css";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

function DashboardMenu({ setUnderDevelopment, setPermissionDenied }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logout } = useGetCurrentUser();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const cartItem = localStorage.getItem("SET_PRODUCT") ? (
    <FontAwesomeIcon icon={faCircle} id="cart-circle" />
  ) : (
    ""
  );

  return (
    <div className="menu">
      <div className="logo-container">
        <Link to="/dashboard">
          <img src={logo} alt="Portfolio Tracker Logo" className="logo" />
        </Link>
      </div>
      <input type="checkbox" id="menu-toggle" />
      <label
        htmlFor="menu-toggle"
        className="menu-icon dashboard-toggle"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} />
      </label>
      <div className={isMenuOpen ? "menu-container-show" : "menu-container"}>
        <nav role="navigation" className="nav-menu-main dashboard-nav">
          <ul className="nav-menu dashboard-menu">
            <li>
              <Link
                to="/portfolio"
                className={
                  location.pathname === "/portfolio"
                    ? "menu-img active"
                    : "menu-img"
                }
              >
                <FontAwesomeIcon icon={faChartPie} alt="Portfólióm" />
              </Link>
            </li>
            <li>
              <Link onClick={setUnderDevelopment} className="menu-img">
                <FontAwesomeIcon
                  icon={faSquarePollVertical}
                  alt="Statisztika"
                />
              </Link>
            </li>
            <li>
              <Link onClick={setUnderDevelopment} className="menu-img">
                <FontAwesomeIcon icon={faCrosshairs} alt="Figyelőlista" />
              </Link>
            </li>
            <li>
              <Link onClick={setUnderDevelopment} className="menu-img">
                <FontAwesomeIcon icon={faChartLine} alt="Elemzések" />
              </Link>
            </li>
            <li>
              <Link onClick={setUnderDevelopment} className="menu-img">
                <FontAwesomeIcon icon={faChessKnight} alt="Stratégia" />
              </Link>
            </li>
            <li>
              <Link to="/kosar" className="menu-img" id="kosar">
                <FontAwesomeIcon icon={faCartShopping} alt="Kosár" />
                {cartItem}
              </Link>
            </li>
            {/* <li><Link onClick={setUnderDevelopment} className="menu-img">
          <FontAwesomeIcon icon={faGear} alt="Beállítások" />
        </Link></li> */}
            <li>
              <Link to="/" className="menu-img">
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  alt="Kijelentkezés"
                  onClick={logout}
                />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default DashboardMenu;
