import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PortfolioTracker.css";
import Frame from "../components/UI/WebsiteFrame";
import Tables from "../components/Tables";
import chess from "../assets/chess.png";
import increase from "../assets/increase.png";
import pie_chart from "../assets/pie-chart.png";
import watchlist from "../assets/watchlist.png";

function PortfolioTracker() {
  const setCart = () => {
    localStorage.setItem("SET_PRODUCT", "portfolioTracker");
  };
  return (
    <Frame>
        <Helmet>
    <title>Portfolio Tracker - Tőzsdézz bátran! - Portfolio Tracker</title>
    <meta
      name="description"
      content="Alkoss stratégiát, meríts ihletet az elemzésekből, kövesd nyomon a portfóliód alakulását, tartsd szemmel a kiszemelt részvényeket."
    />
  </Helmet>
      <div className="pt-container">
        <div className="pt-hero widget-container">
          <div className="table-container">
            <Tables title="table-title" />
          </div>
          <div className="pt-headline">
            <span>Kezeld a portfóliód profiként</span>
            <h1>Portfolio Tracker előfizetés</h1>
            <section>
              Alkoss stratégiát, meríts ihletet az elemzésekből, kövesd nyomon a
              portfóliód alakulását, tartsd szemmel a kiszemelt részvényeket.
              Minden a rendelkezésedre áll ahhoz, hogy a legtöbbet hozd ki a
              részvényeidből.
            </section>
          </div>
        </div>
      </div>
      <div className="pt-services widget-container">
        <div className="pt-services-headline">
          <h2>Miért érdemes előfizetned a Portfolio Tracker-re?</h2>
          <p>
            Kezeld a portfóliódat úgy, mint egy profi. Itt mindent megtalálsz,
            amire szükséged lesz ahhoz, hogy sikeres befektetővé válj.
          </p>
        </div>
        <div className="pt-list">
          <div className="pt-list-column">
            <div className="pt-list-element">
              <img src={pie_chart} />
              <div className="pt-list-content">
                <h3>Portfolió</h3>
                <p>
                  Vezesd a portfóliód úgy, mint a profik. Minden fontos
                  információ egy helyen, átláthatóan, hogy te a megfelelő
                  pillanatban a legjobb döntéseket hozhasd meg.
                </p>
              </div>
            </div>
            <div className="pt-list-element">
              <img src={watchlist} />
              <div className="pt-list-content">
                <h3>Figyelőlista</h3>
                <p>
                  Kemény és kitartó munkával felkutattad a kedvenc részvényeidet
                  és nem szeretnél lemaradni az akciós árfolyamukról? A
                  figyelőlistáddal egyetlen lehetőségről sem maradsz le! A remek
                  ajánlatok ritkák és rövid ideig elérhetőek, de neked nem kell
                  aggódnod. Napi szinten nyomon követheted kedvenc részvényeid
                  ár/érték alakulását, így azonnal lecsaphatsz a nagyszerű
                  lehetőségekre.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-list-column">
            <div className="pt-list-element">
              <img src={chess} />
              <div className="pt-list-content">
                <h3>Stratégia & Statisztika</h3>
                <p>
                  Alakítsd ki a nyerő stratégádat és kövesd nyomon a betartását.
                  Egy jó stratégia aranyat ér. Megvéd a bajban és segíti abban,
                  hogy minden helyzetben jó döntéseket hozz.
                </p>
              </div>
            </div>
            <div className="pt-list-element">
              <img src={increase} />
              <div className="pt-list-content">
                <h3>Elemzések</h3>
                <p>
                  Meríts ötleteket a folyamatosan bővülő vállalatelemzésekből. A
                  nagyszerű vállalatok felkutatása sokszor fárasztó és
                  időigényes munka. Itt kézhez kapod több mint 100 vállalat
                  elemzését, hogy neked már ne kelljen a nulláról kezdeni.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-advantages-container">
        <div className="pt-advantages widget-container">
          <div className="pt-advantages-list">
            <section>
              <h2>Mit kapsz havi egy kávé áráért?</h2>
              <ol>
              <li>Hozzáférsz több mint 100 vállalat elemzéséhez</li>
              <li>Profi portfólió kezelő eszközt</li>
              <li>Stratégia alkotó eszközt</li>
              <li>Vizuális elemzéstámogató eszközt</li>
              <li>Kialakíthatod a befektetést támogató figyelőlistádat</li>
              </ol>
              <p>BÓNUSZ:</p>
              <ul className="book-bonus">
              <li>Letöltheted a könyvet elektronikus formában</li>
              <li>Letöltheted az értékeléshez szükséges segédfájlokat</li>
              <li>Letöltheted az elemzésekhez szükséges segédfájlokat</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="cta-container">
        <div className="cta widget-container">
          <h3>Mire vársz? Fizess elő most!</h3>
          <Link
            to="/kosar"
            onClick={setCart}
            className="btn lg-button lg-button-border"
          >
            Előfizetek
          </Link>
        </div>
      </div>
    </Frame>
  );
}

export default PortfolioTracker;
