import React, { useState, useEffect } from "react";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";

function BillingDetails({ onComponentChange, setBillingDataHandler }) {
  const { getCurrentUser } = useGetCurrentUser();
  const [name, setName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await getCurrentUser();
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const nameHandleChange = (event) => {
    setName(event.target.value);
  };
  const postalCodeHandleChange = (event) => {
    setPostalCode(event.target.value);
  };
  const cityHandleChange = (event) => {
    setCity(event.target.value);
  };
  const postalAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const houseNumberChange = (event) => {
    setHouseNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onComponentChange("payment");
    setBillingDataHandler({
      name: name,
      postalCode: postalCode,
      city: city,
      address: address,
      houseNumber: houseNumber,
    });
  };

  return (
    <>
      <div className="login-container">
        <h2>Számlázási adatok</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <label htmlFor="name" className="input-label">
            Számlázási név
          </label>
          <input
            type="text"
            className="input-column billing-data"
            value={name}
            onChange={nameHandleChange}
            required
          />
          <div className="address-column">
            <div className="billing-element postal-code">
              <label htmlFor="postal-code" className="input-label">
                Irányítószám
              </label>
              <input
                type="number"
                className="input-column billing-data"
                value={postalCode}
                onChange={postalCodeHandleChange}
                required
              />
            </div>

            <div className="billing-element city">
              <label htmlFor="city" className="input-label">
                Város
              </label>
              <input
                type="text"
                className="input-column billing-data"
                value={city}
                onChange={cityHandleChange}
                required
              />
            </div>
          </div>

          <div className="address-column">
            <div className="billing-element address">
              <label htmlFor="address" className="input-label">
                Cím
              </label>
              <input
                type="text"
                className="input-column billing-data"
                value={address}
                onChange={postalAddressChange}
                required
              />
            </div>

            <div className="billing-element house-number">
              <label htmlFor="house-number" className="input-label">
                Házszám
              </label>
              <input
                type="text"
                className="input-column billing-data"
                value={houseNumber}
                onChange={houseNumberChange}
                required
              />
            </div>
          </div>

          <div className="login-btn">
            <button type="submit" className="btn gr-button">
              Tovább
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default BillingDetails;
