import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Aszf.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function Aszf() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Általános Szerződési Feltételek - Portfolio Tracker</title>
        <meta
          name="description"
          content="Itt találsz mindent amit az általános szerződési feltételekről tudni kell."
        />
      </Helmet>
      <div className="container dark_bg">
        <NavBar />
        <div className="aszf-container widget-container">
          <div className="aszf-content-container">
            <div className="aszf-headline"></div>
            <div className="aszf-content">
              <h1 className="aszf-title">Általános Szerződési Feltételek</h1>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Preambulum</h2>
            </div>
            <div className="aszf-content">
              <p>
                Kérjük, olvassa el figyelmesen az Általános Szerződési
                Feltételeinket mielőtt használná a weboldalt, szolgáltatást
                rendelne meg vagy terméket vásárolna.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Impresszum: a Szolgáltató (Eladó, Vállalkozás) adatai</h2>
            </div>
            <div className="aszf-content">
              <p>
                A jelen Általános Szerződési Feltételek (a továbbiakban: ÁSZF)
                portfoliotracker.hu és Zvolensky Tibor E.V. (továbbiakban:
                Szolgáltató), és a Szolgáltató által a portfoliotracker.hu
                weboldalon keresztül nyújtott elektronikus kereskedelmi
                szolgáltatásokat igénybe vevő Ügyfél (a továbbiakban: Ügyfél)
                jogait és kötelezettségeit tartalmazza (Szolgáltató és Ügyfél a
                továbbiakban együttesen: Felek).
              </p>
              <p>
                Az ÁSZF minden jogügyletre és szolgáltatásra vonatkozik, amely a
                portfoliotracker.hu weboldalon keresztül történik, függetlenül
                attól, hogy annak teljesítése Magyarországról vagy külföldről, a
                Szolgáltató vagy közreműködője által történik.
              </p>
              <p>
                Kérjük, amennyiben vásárlója, illetve aktív felhasználója kíván
                lenni a portfoliotracker.hu weboldalunknak, figyelmesen olvassa
                el az Általános Szerződési Feltételeinket és kizárólag abban az
                esetben vegye igénybe szolgáltatásainkat, amennyiben minden
                pontjával egyetért, és kötelező érvényűnek tekinti magára nézve.
              </p>
              <p>
                Jelen dokumentum nem kerül iktatásra, kizárólag elektronikus
                formában kerül megkötésre, későbbiekben nem kereshető vissza,
                magatartási kódexre nem utal.
              </p>
              <p>
                A weboldal működésével, megrendelési, és szállítási folyamatával
                kapcsolatosan felmerülő kérdések esetén a megadott
                elérhetőségeken rendelkezésedre állunk!
              </p>
              <p>A portfoliotracker.hu üzemeltetője: Zvolensky Tibor E.V.</p>
              <p>Székhelye: 2112 Veresegyház, Etűd utca 8.</p>
              <p>Adószám: 41911316-1-33</p>
              <p>Nyilvántartási szám: 58150623</p>
              <p>Elérhetőség: info@portfoliotracker.hu</p>
              <p>Szerződés nyelve: magyar</p>
              <p>A tárhely-szolgáltató neve, címe, e-mail címe:</p>
              <ul>
                <li>DigitalOcean, LLC</li>
                <li>contact@digitalocean.com</li>
                <li>New York, 101 6th Ave, United States</li>
              </ul>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Általános tudnivalók</h2>
            </div>
            <div className="aszf-content">
              <p>
                1.1. A portfoliotracker.hu weboldal e-könyvet forgalmaz,
                vállalatok elemzésével foglalkozik, vállalatok elemzéséhez kínál
                segédanyagot és portfólió vezetési szolgáltatást nyújt.
              </p>
              <p>
                1.2. A termékek és szolgáltatások kizárólag a weboldal online
                megrendelő űrlapjain keresztül rendelhetőek meg.
              </p>
              <p>
                1.3. Az Ügyfél elfogadja, hogy magát a Weboldalt, valamint
                minden olyan tartalmat, amelyet a Szolgáltató vagy más harmadik
                fél tesz elérhetővé a Weboldalon, valamint a fentiek
                megjelenését (együttesen "Tartalom") a Szolgáltató tartja fenn
                az Ügyfél személyes használatára és tájékoztatására, és a
                Szolgáltató és/vagy annak partnerei tulajdonát képezik. Az
                Ügyfél elfogadja, hogy a Szolgáltató ilyen tartalma magában
                foglalja az összes saját tulajdonú videót, HTML/CSS-t,
                Javascriptet, grafikát, hangfelvételt, művészeti alkotást,
                fényképet, dokumentumot és szöveget, valamint a Webhelyen
                található minden egyéb anyagot, kivéve kizárólag az Ügyfél által
                megadott anyagokat.
              </p>
              <p>
                A Szolgáltató tartalma nem másolható, reprodukálható,
                továbbítható, terjeszthető, nem tehető újra közzé, nyilvános
                vagy kereskedelmi célokra nem használható, kivéve, ha a
                Szolgáltató erre kifejezetten írásos engedélyt ad. A Tartalom
                módosítása vagy bármilyen más célú felhasználása a Szolgáltató,
                valamint az anyagokat létrehozó más szerzők szerzői és egyéb
                tulajdonosi jogainak megsértését jelenti, és pénzbeli
                kártérítéssel és büntetéssel járhat. Szolgáltató kifejezett
                írásbeli engedélye nélkül nem terjesztheti, módosíthatja,
                továbbíthatja vagy használhatja a Weboldal tartalmát, vagy
                bármely Tartalmat nyilvános vagy kereskedelmi célokra.{" "}
              </p>
              <p>
                1.5. Az Ügyfél megérti és elfogadja, hogy a weboldalon található
                elemzések és információk nem tekinthetők és nem minősülnek
                ajánlattételi felhívásnak, befektetési tanácsadásnak vagy
                adótanácsadásnak. A leírtak célja kizárólag tájékoztató jellegű
                és az adott téma, termék vagy szolgáltatás megismerését
                szolgálják.
              </p>
              <p>
                1.6. A jelen Megállapodásban vagy a Webhelyen semmi sem
                értelmezhető úgy, hogy a Szolgáltató vagy a harmadik fél
                kifejezett írásbeli hozzájárulása nélkül hallgatólagosan vagy
                más módon engedélyt vagy jogot adna bármely Védjegy vagy egyéb
                tulajdonosi információ használatára. Szolgáltató tiszteletben
                tartja mások szerzői jogait, védjegyeit és minden egyéb szellemi
                tulajdonjogát. Szolgáltatónak joga van, de nem kötelessége
                eltávolítani az olyan tartalmakat és fiókokat, amelyek saját
                belátása szerint jogellenesnek, sértőnek, fenyegetőnek,
                rágalmazónak, becsületsértőnek, pornográfnak, obszcénnek vagy
                más módon kifogásolhatónak minősülnek, illetve sértik bármely
                fél szellemi tulajdonát vagy a jelen Felhasználási Feltételeket.
              </p>
              <p>
                1.7. Ha úgy véli, hogy szellemi tulajdonjogai sérülnek és/vagy
                az Ön tulajdonát képező bármely művet bármilyen módon
                reprodukálták a Webhelyen vagy bármely Tartalomban, értesítheti
                a Szolgáltatót az info@portfoliotracker.hu címen. Kérjük, adja
                meg nevét és elérhetőségét, a művének jellegét és a jogsértés
                módját, minden vonatkozó szerzői jogi és/vagy védjegybejegyzési
                információt, a jogsértés helyét/URL-jét, valamint minden egyéb,
                Ön szerint releváns információt.
              </p>
              <p>
                1.8. A portfoliotracker.hu weboldal szolgáltatásainak egy része
                elérhető minden felhasználó számára, előfizetés nélkül is. A
                szolgáltatások azonban regisztrációhoz (majd belépéshez)
                kötöttek.
              </p>
              <p>
                1.9. Amikor az Ügyfél regisztrál a Weboldalon, kifejezetten
                hozzájárul ahhoz, hogy a Szolgáltatótól bármilyen értesítést,
                bejelentést, megállapodást, közzétételt, jelentést,
                dokumentumot, új termékekre vagy szolgáltatásokra vonatkozó
                közleményt, illetve egyéb feljegyzést vagy levelezést kapjon. Az
                Ügyfél hozzájárul ahhoz, hogy az értesítéseket elektronikus
                úton, e-mailben történő továbbítása útján kapja meg.
              </p>
              <p>
                1.10. A Szolgáltató szándékosan nem ad ki harmadik félnek
                semmilyen Ügyfélre vonatkozó, személyazonosításra alkalmas
                információt, kivéve, ha Szolgáltató jóhiszeműen úgy véli, hogy
                az ilyen közzététel szükséges a jogszabályoknak való
                megfeleléshez vagy a jelen Felhasználási Feltételek
                érvényesítéséhez. A Weboldalon történő regisztrációval az Ügyfél
                jelzi, hogy elfogadja a Szolgáltató Adatkezelési Nyilatkozatát.
                Ha Ön nem ért egyet az Adatkezelési Nyilatkozattal akár részben,
                akár egészben, kérjük, ne használja a Weboldalt.
              </p>
              <p>
                1.11. A díjazás ellenében elérhető termékek és szolgáltatások
                egyedi hozzáférésre vonatkoznak. A hozzáférés törlésre kerül, ha
                írásos engedély nélkül egynél több személy használja a terméket,
                és a visszatérítés megtagadásra kerül.
              </p>
              <p>
                1.12. A szolgáltatásra történő előfizetés időtartama az
                előfizetés napjától számított egy év. Az előfizetés nem újul meg
                automatikusan. Az előfizetés lejárta előtt legalább 30 nappal a
                Szolgáltató emlékeztetők küld az Ügyfélnek, melyben felhívja az
                Ügyfél figyelmét a szolgáltatás lejártának határidejére.
                Amennyiben a szolgáltatás nem kerül megújításra az Ügyfél által,
                abban az esetben az Ügyfél elveszíti az előfizetés által
                biztosított termékek, modulok elérhetőségét és hozzáférését,
                beleértve az Ügyfél által felvitt adatok elérhetőségét is. Az
                előfizetés időtartamának lejárta után a Szolgáltató nem
                kötelezhető az Ügyfél által felvitt adatok Ügyfél számára
                történő átadására bármilyen formátumban. A Szolgáltató által
                küldött emlékeztető tartalmazza az adatvesztés szabályára
                vonatkozó információt.
              </p>
              <p>
                1.13. Az Ügyfélnek hozzáférése van ehhez a programterülethez
                mindaddig, amíg a programterület létezik. Abban az esetben, ha
                Szolgáltató be kívánja zárni a Programterületet, legalább 30
                nappal korábban értesítést küld az Ügyfélnek, és lehetőséget
                biztosít a Programterületen található alapvető erőforrások
                letöltésére.
              </p>
              <p>
                1.14. Szolgáltató fenntartja a jogot a termékek és szolgáltatás
                megszüntetésére, de vállalja, hogy az ilyen megszüntetésekről
                legalább 30 nappal korábban értesíti az Ügyfelet. Előfizetés
                esetén a Szolgáltató vállalja, hogy időarányosan megtéríti a
                szolgáltatás díját.
              </p>
              <p>
                1.15. A Szolgáltatónak jogában áll kedvezményeket, bónuszokat
                adni a termékekre és szolgáltatásra. Az Ügyfél jogosult igénybe
                venni a kedvezményeket és bónuszokat azok Szolgáltató által
                meghatározott időtartama alatt. A kedvezmények és bónuszok
                visszamenőlegesen nem érvényesíthetők, a már megvásárolt
                termékekre és szolgáltatásokra visszamenőlegesen nem
                felhasználhatók.{" "}
              </p>
              <p>1.16. A szerződés nyelve magyar. </p>
              <p>
                1.17. A jelen ÁSZF hatálya kiterjed a Magyarország területén
                nyújtott minden olyan elektronikus kereskedelmi szolgáltatásra,
                amely a portfoliotracker.hu weboldalon található elektronikus
                áruházon (a továbbiakban: portfoliotracker.hu webáruház)
                keresztül történik. Továbbá jelen ÁSZF hatálya kiterjed minden
                olyan kereskedelmi ügyletre Magyarország területén, amely jelen
                szerződésben meghatározott Felek között jön létre. A
                portfoliotracker.hu webáruházban történő vásárlást az
                elektronikus kereskedelmi szolgáltatások, az információs
                társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló
                2001. évi CVIII. törvény szabályozza.
              </p>
              <p>
                1.18. A Szolgáltató semmilyen magatartási kódex rendelkezéseinek
                nem veti alá magát.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Megrendelés menete</h2>
            </div>
            <div className="aszf-content">
              <p>
                2.1. A termékek és szolgáltatások megrendelése regisztrációhoz
                kötött. Regisztrációra a regisztrációs adatlap kitöltésével
                kerül sor. A megrendeléshez minden, az Ügyfél adataira vonatkozó
                mező maradéktalan kitöltése szükséges. Ha az Ügyfél valamely
                mezőt hibásan vagy hiányosan tölt ki, hibaüzenetet kap a
                Szolgáltatótól, és a regisztráció/megrendelés meghiúsul a hiba
                javításáig. A Szolgáltatót az Ügyfél által tévesen és/vagy
                pontatlanul megadott rendelési adatokra visszavezethető
                szállítási késedelemért, illetve egyéb problémáért, hibáért
                semminemű felelősség nem terheli.
              </p>
              <p>
                2.2. Az Ügyfél a Weboldalon történő regisztrációjával, és a
                rendelés során bepipált mezővel kijelenti, hogy jelen ÁSZF, és a
                Weboldalon közzétett Adatvédelmi Nyilatkozat feltételeit
                megismerte és elfogadja, az Adatvédelmi Nyilatkozatban foglalt
                adatkezelésekhez hozzájárul.
              </p>
              <p>
                2.3. A Szolgáltatót az Ügyfél által tévesen és/vagy pontatlanul
                megadott adatokra visszavezethető szállítási késedelemért,
                illetve egyéb problémáért, hibáért semminemű felelősség nem
                terheli.
              </p>
              <p>
                2.4. A Szolgáltatót nem terheli felelősség az abból adódó
                károkért, ha az Ügyfél a jelszavát elfelejti, vagy az
                illetéktelenek számára bármely nem a Szolgáltatónak felróható
                okból hozzáférhetővé válik. A Szolgáltató minden regisztrációt
                egy önálló jogi személyként kezel.
              </p>
              <p>
                2.5. A korábban rögzített adatok megváltoztatására bejelentkezés
                után, a www.portfoliotrackert.hu/my-account oldalon elérhető
                Személyes adatok módosítása menüpontban van lehetőség, amely az
                aktív megrendelések adatait is érintheti.
              </p>
              <p>
                2.6. Szolgáltatót a regisztrált adatok ügyfél által történő
                megváltoztatásából eredő kárért, hibáért semminemű felelősség
                nem terheli.
              </p>
              <p>
                2.7. A Szolgáltató szerződésszerű teljesítésének minősül, ha a
                termék a weboldalon vagy a használati utasításban nyújtott
                tájékoztatásnál kedvezőbb, előnyösebb tulajdonságokkal
                rendelkezik. Ha a vásárlás előtt az áruval kapcsolatban kérdése
                merülne fel, az info@portfoliotracker.hu e-mail címre tudja
                elküldeni a kérdéseit.
              </p>
              <p>
                Amennyiben a Weboldalon található bármely áru minőségével,
                alapvető tulajdonságával, használatával, használhatóságával
                kapcsolatban, a weboldalon közölteknél több információra van
                szüksége, kérjük írásban forduljon a Szolgáltatóhoz, az
                info@portfoliotracket.hu mail címen keresztül.{" "}
              </p>
              <p>
                2.8. A megrendelt termékek és szolgáltatások kizárólag
                bankkártyával, a Simple Pay rendszerén keresztül fizethetők ki.
              </p>
              <p>
                2.9. Az árak bruttó árak, melyek tartalmazzák az áfát, kivéve
                ahol külön fel van tüntetve az összeg+ÁFA megjelöléssel, hogy
                nettó árról van szó, amihez az ÁFA összegét (27%) hozzáadódik. A
                rendelés leadásakor a kosárban mindig a bruttó ár kerül
                feltüntetésre, az adótartalom megjelölésével, kivéve ahol nincs
                feltűntetve külön adótartalom.
              </p>
              <p>
                2.10. A Weboldalról megrendelhető termékek árai változtatásának
                jogát a Szolgáltató fenntartja azzal, hogy a módosítás a
                Weboldalon való megjelenéssel egyidejűleg lép hatályba. A
                módosítás a már megrendelt termékek vételárát kedvezőtlenül nem
                befolyásolja. Online bankkártyával történő fizetés
                kezdeményezésekor, az elektronikus fizetési értesítő kiküldése
                és a termék átvétele közti időszakban bekövetkezett árcsökkenés
                a Szolgáltató megkeresése esetén a különbséget szívesen
                visszatérítjük.
              </p>
              <p>
                2.11. Amennyiben a Szolgáltató minden gondossága ellenére hibás
                ár kerül a Weboldal felületére, különös tekintettel a
                nyilvánvalóan téves, pl. a termék közismert, általánosan
                elfogadott vagy becsült árától jelentősen eltérő, esetleg
                rendszerhiba miatt megjelenő “0” Ft-os vagy “1” Ft-os árra,
                akkor a Szolgáltató nem köteles a terméket hibás áron
                rendelkezésre bocsátani, hanem felajánlhatja a helyes áron
                történő vásárlás lehetőségét, amelynek ismeretében az Ügyfél
                elállhat vásárlási szándékától.
              </p>
              <p>
                2.12. A weboldalon megjelentetett termékképek helyenként csak
                illusztrációk, azok a valóságtól eltérhetnek.
              </p>
              <p>
                2.13. A rendelés leadása a „Megrendelem” gombra kattintással
                történik, ami az Ügyfél részére fizetési kötelezettséget
                keletkeztet.{" "}
              </p>
              <p>
                2.14. Az Ügyfél által elküldött megrendelés megérkezését a
                Szolgáltató késedelem nélkül, automatikus visszaigazoló e-mail
                útján legkésőbb 48 órán belül visszaigazolja az Ügyfél részére,
                mely visszaigazoló e-mail tartalmazza:
              </p>
              <ul>
                <li>
                  az Ügyfél által a vásárlás, vagy a regisztráció során megadott
                  adatokat (pl. számlázási és szállítási információk),
                </li>
                <li>a rendelés azonosítóját,</li>
                <li>a rendelés dátumát,</li>
                <li>
                  a megrendelt termékhez tartozó elemek felsorolását,
                  mennyiségét, a termék árát,
                </li>
                <li>szállítási költséget</li>
                <li>és a fizetendő végösszeget.</li>
              </ul>
              <p>
                Ez a visszaigazoló email kizárólag tájékoztatja az Ügyfelet
                arról, hogy a megrendelése a Szolgáltatóhoz megérkezett.
              </p>
              <p>
                2.15. Az Ügyfél mentesül az ajánlati kötöttség alól, ha
                késedelem nélkül, vagyis 48 órán belül nem kapja meg
                Szolgáltatótól az elküldött rendelésére vonatkozó külön elfogadó
                e-mailt.
              </p>
              <p>
                2.16. Ha az Ügyfél rendelését már elküldte a Szolgáltató részére
                és hibát vesz észre a visszaigazoló e-mailben szereplő adatok
                tekintetében, azt 1 napon belül jeleznie kell a Szolgáltató
                felé.
              </p>
              <p>
                2.17. A megrendelés elektronikus úton megkötött szerződésnek
                minősül, amelyre a polgári törvénykönyvről szóló 2013. évi V.
                törvény, az elektronikus kereskedelmi szolgáltatások, valamint
                az információs társadalommal összefüggő szolgáltatások egyes
                kérdéseiről szóló 2001. évi CVIII. törvényben foglaltak
                irányadóak.
              </p>
              <p>
                2.18. A szerződés a fogyasztó és a vállalkozás közötti
                szerződések részletes szabályairól szóló 45/2014 (II.26.) Korm.
                rendelet hatálya alá tartozik, és szem előtt tartja a fogyasztók
                jogairól szóló Európai Parlament és a Tanács 2011/83/EU
                irányelvének rendelkezéseit.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Elállási jog</h2>
            </div>
            <div className="aszf-content">
              <p>
                3.1. A távollevők között kötött szerződésekről szóló, 17/1999.
                (11.5.) kormányrendelet szabályozása értelmében a fogyasztó a
                megrendelt termék kézhez vételétől számított 14 munkanapon belül
                indoklás nélkül elállhat a szerződéstől.
              </p>
              <p>
                3.2. Ha a Fogyasztó elállási jogával élni kíván, elállási
                szándékát tartalmazó egyértelmű nyilatkozatát írásban köteles
                eljuttatni (például postán vagy elektronikus úton küldött levél
                útján) a jelen ÁSZF elején feltüntetett elérhetőségek
                igénybevételével a Szolgáltató részére. Fogyasztó határidőben
                gyakorolja elállási jogát, ha a fent megjelölt határidő lejárta
                előtt elküldi elállási nyilatkozatát a Szolgáltató részére.
              </p>
              <p>
                3.3. Minden esetben a Szolgáltató emailben haladéktalanul
                visszaigazolja a Fogyasztó elállási nyilatkozatának
                megérkezését.
              </p>
              <p>
                3.4. Az írásban történő elállás esetén azt határidőben
                érvényesítettnek kell tekinteni, ha Fogyasztó az erre irányuló
                nyilatkozatát 14 naptári napon belül (akár a 14. naptári napon)
                elküldi a Szolgáltatónak.
              </p>
              <p>
                3.5. Postai úton történő jelzés alkalmával a postára adás
                dátumát, e-mailen keresztül történő értesítés esetén az e-mail
                illetve az e-mail küldésének idejét veszi figyelembe a
                Szolgáltató a határidő számítás szempontjából. A Fogyasztó
                levelét ajánlott küldeményként adja postára, hogy hitelt
                érdemlően bizonyítható legyen a feladás dátuma.
              </p>
              <p>
                3.6. Ha Fogyasztó eláll a szerződéstől, haladéktalanul, de
                legkésőbb a Fogyasztó elállási nyilatkozatának kézhezvételétől
                számított 14 napon belül a Szolgáltató visszatéríti a Fogyasztó
                által teljesített valamennyi ellenszolgáltatást.
              </p>
              <p>
                3.7. A visszatérítés során az eredeti ügylet során alkalmazott
                fizetési móddal egyező fizetési módot alkalmaz a Szolgáltató,
                kivéve, ha Fogyasztó más fizetési mód igénybevételéhez
                kifejezetten a hozzájárulását adja; e visszatérítési mód
                alkalmazásából kifolyólag Fogyasztót semmilyen többletköltség
                nem terheli.
              </p>
              <p>
                3.8. Ha Fogyasztó a szolgáltatásnyújtásra irányuló szerződés
                esetén a teljesítés megkezdését megkötését követően gyakorolja
                felmondási jogát, úgy az elszámolás során köteles megtéríteni a
                Szolgáltató ésszerű költségeit.
              </p>
              <p>
                3.9. Visszatérítést esetén, azonnal visszavonóik a Felek közötti
                megállapodás. A Szolgáltató visszavonja az Ügyfél számára
                rendelkezésére bocsátott anyagok használatára adott valamennyi
                engedélyt. Az Ügyfélnek azonnal fel kell hagynia az anyag
                használatával, és meg kell semmisítenie az átadott információk,
                sablonok, dokumentumok minden másolatát.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Jótállás</h2>
            </div>
            <div className="aszf-content">
              <p>
                4.1. A Szolgáltatót termékeire vonatkozóan a Polgári
                törvénykönyv és a 151/2003. (IX. 22.) Korm. rendelet alapján
                jótállási kötelezettség terheli, amely azt jelenti, hogy a
                jótállás időtartama alatt a felelősség alól csak akkor mentesül,
                ha bizonyítja, hogy a hiba a termék nem rendeltetésszerű
                használatára vezethető vissza.
              </p>
              <p>
                4.2. A jótállás időtartama (a jótállási idő) a tényleges
                teljesítéssel, tehát a terméknek az Ügyfél részére történő
                átadásával, vagy ha az üzembe helyezést a Szolgáltató vagy annak
                megbízottja végzi, az üzembe helyezés napja. A rendelet (tárgyi)
                hatálya csakis az új, Magyarország területén kötött fogyasztói
                szerződés keretében értékesített és a rendelet mellékletében
                felsorolt termékekre vonatkozik.{" "}
              </p>
              <p>
                4.3. A jótállási kötelezettség teljesítésével kapcsolatos
                költségek a Szolgáltatót terhelik.
              </p>
              <p>
                4.4. A Szolgáltató jótállási kötelezettsége alól csak abban az
                esetben mentesül, ha bizonyítja, hogy a hiba oka a teljesítés
                után keletkezett.
              </p>
              <p>
                4.5. Az Ügyfelet azonban nem illeti meg az a jog, hogy ugyanazon
                hiba miatt kellékszavatossági és jótállási igényt, illetve
                termékszavatossági és jótállási igényt egyszerre, egymással
                párhuzamosan érvényesítsen. Ezen korlátozásoktól függetlenül
                Vásárlót a jótállásból fakadó jogok a 5.1 és a 5.2 pontokban
                meghatározott jogosultságoktól függetlenül megilletik.
              </p>
              <p>
                4.6. A jótállás nem érinti az Ügyfél jogszabályból eredő – így
                különösen kellék- és termékszavatossági, illetve kártérítési –
                jogainak érvényesítését.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Szerzői jogok</h2>
            </div>
            <div className="aszf-content">
              <p>
                5.1. A Weboldalon található információk jóhiszeműen kerültek
                felhelyezésre, azonban azok kizárólag tájékoztató célt
                szolgálnak, az információk pontosságáért, teljességéért
                Szolgáltató nem vállal felelősséget.
              </p>
              <p>
                5.2. Az Ügyfél a Weboldalt kizárólag a saját kockázatára
                használhatja, és elfogadja, hogy Szolgáltató nem vállal
                felelősséget a használat során felmerülő vagyoni és nem vagyoni
                károkért a szándékosan, súlyos gondatlansággal, vagy
                bűncselekménnyel okozott, továbbá az életet, testi épséget,
                egészséget megkárosító szerződésszegésért való felelősségen
                túlmenően.
              </p>
              <p>
                5.3. Szolgáltató kizár minden felelősséget a Weboldal használói
                által tanúsított magatartásért. Az Ügyfél teljes mértékben és
                kizárólagosan felelős saját magatartásáért, Szolgáltató ilyen
                esetben teljes mértékben együttműködik az eljáró hatóságokkal a
                jogsértések felderítése végett.
              </p>
              <p>
                5.4. A szolgáltatás oldalai olyan kapcsolódási pontokat
                (linkeket) tartalmazhatnak, amely más szolgáltatók oldalaira
                vezet. Ezen szolgáltatók adatvédelmi gyakorlatáért és más
                tevékenységéért Szolgáltató nem vállal felelősséget.
              </p>
              <p>
                5.5. Az Ügyfelek által a Weboldal használata során esetlegesen
                elérhetővé tett tartalmat a Szolgáltató jogosult, de nem köteles
                ellenőrizni, és a közzétett tartalmak tekintetében a Szolgáltató
                jogosult, de nem köteles jogellenes tevékenység folytatására
                utaló jeleket keresni.
              </p>
              <p>
                5.6. Az Internet globális jellege miatt az Ügyfél elfogadja,
                hogy a Weboldal használata során a vonatkozó nemzeti
                jogszabályok rendelkezéseit is figyelembe véve jár el.
                Amennyiben a Weboldal használatával összefüggő bármely
                tevékenység az Ügyfél államának joga szerint nem megengedett, a
                használatért kizárólag az Ügyfelet terheli a felelősség.
              </p>
              <p>
                5.7. Szolgáltató semmilyen körülmények között nem vállal
                felelősséget semmilyen különleges, véletlen, közvetett, büntető
                jellegű, függő vagy következményes kárért, függetlenül attól,
                hogy az előre látható volt-e vagy sem, beleértve, de nem
                kizárólagosan, a tulajdonban, berendezésekben, információkban
                vagy adatokban bekövetkezett kárt vagy veszteséget,
                nyereségkiesést, bevételkiesést vagy jó hírnevet, tőkeköltséget,
                a helyettesítő szolgáltatások költségét, vagy a szolgáltatás
                megszakításából vagy átviteli problémákból eredő igényeket,
                amelyek a Webhely, a Tartalom és/vagy a kapcsolódó anyagok
                bármilyen hibája, a jelen szerződés alapján nyújtott
                szolgáltatások használatának lehetetlensége vagy bármilyen más
                ok miatt keletkeztek, függetlenül a felelősség elméletétől. Ez a
                korlátozás akkor is érvényes, ha Szolgáltatót tájékoztatták az
                ilyen károk lehetőségéről, vagy ha tudott róla.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Felelősség</h2>
            </div>
            <div className="aszf-content">
              <p>
                6.1. Az oldalon található ingyenes és fizetéssel elérhető
                információkat a szerzői jogról szóló 1999. évi LXXVI. törvény
                alapján a szerzői jog védi.
              </p>
              <p>A szerzőt megillető jogok a következőek:</p>
              <ol type="a">
                <li>a többszörözés (Szjt. 18-19. §),</li>
                <li>a terjesztés (Szjt. 23. §),</li>
                <li>a nyilvános előadás (Szjt. 24-25. §),</li>
                <li>
                  a nyilvánossághoz közvetítés sugárzással vagy másként (Szjt.
                  26-27. §),
                </li>
                <li>
                  a sugárzott műnek az eredetihez képest más szervezet
                  közbeiktatásával a nyilvánossághoz történő továbbközvetítése
                  (Szjt. 28. §),
                </li>
                <li>az átdolgozás (Szjt. 29. §),</li>
                <li>a kiállítás (Szjt. 69. §).</li>
              </ol>
              <p>
                A szerzői joggal való bármilyen visszaélés, a vásárlás
                feltételeinek nem megfelelő jogszerűtlen felhasználása esetén,
                vagy erre utaló magatartás/utalás/fenyegetés esetén a
                megvásárolt információkhoz való hozzáférés a törvényi
                jogorvoslatig határozatlan időre felfüggesztésre kerül. Az
                oldalon vásárolt termékek és szolgáltatások megosztása/másoknak
                hozzáférés biztosítása esetén a hozzáférés szerződésszegésnek
                minősülő magatartás miatt megszakításra kerül. A Szolgáltató
                ezután polgári peres feljelentést tesz a szerzői jog megsértője
                ellen.
              </p>
              <p>
                6.2. A Weboldal tartalmának, valamint egyes részeinek fizikai
                vagy más adathordozóra mentése vagy kinyomtatása magáncélú
                felhasználás céljából vagy a Szolgáltató előzetes írásbeli
                hozzájárulása esetén engedélyezett. A magáncélú felhasználáson
                túli felhasználás – például adatbázisban történő tárolás,
                továbbadás, közzé- vagy letölthetővé tétel, kereskedelmi
                forgalomba hozatal – kizárólag a Szolgáltató előzetes írásbeli
                engedélyével lehetséges.
              </p>
              <p>
                6.3. A jelen ÁSZF-ben kifejezetten meghatározott jogokon
                túlmenően a regisztráció, a Weboldal használata, illetve az ÁSZF
                egyetlen rendelkezése sem biztosít jogot az Ügyfélnek a Weboldal
                felületén szereplő bármely kereskedelmi névnek vagy védjegynek
                bármely használatára, hasznosítására. A Weboldal
                rendeltetésszerű használatával járó megjelenítésen, az ehhez
                szükséges ideiglenes többszörözésen és a magáncélú
                másolatkészítésen túl e szellemi alkotások a Szolgáltató
                előzetes írásbeli engedélye nélkül semmilyen egyéb formában nem
                használhatók fel vagy hasznosíthatók.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Panaszérvényesítési lehetőség</h2>
            </div>
            <div className="aszf-content">
              <p>
                7.1. Az Ügyfél a termékkel vagy a Szolgáltató tevékenységével
                kapcsolatos fogyasztói kifogásait az alábbi elérhetőségeken
                terjesztheti elő:
              </p>
              <ul>
                <li>Postai levél: 2112 Veresegyház, Etűd utca 8.</li>
                <li>E-mail: info@portfoliotracker.hu</li>
              </ul>
              <p>
                7.2. Online adásvételi vagy online szolgáltatási szerződéssel
                összefüggő határon átnyúló fogyasztói jogvita esetén az
                eljárásra kizárólag a fővárosi kereskedelmi és iparkamara
                mellett működő békéltető testület illetékes.
              </p>
              <p>
                7.3. Amennyiben a vállalkozás székhelye vagy telephelye nem a
                területileg illetékes békéltető testületet működtető kamara
                szerinti megyébe van bejegyezve, a vállalkozás együttműködési
                kötelezettsége a fogyasztó igényének megfelelő írásbeli
                egyezségkötés lehetőségének felajánlására terjed ki.
              </p>
              <p>
                7.4. A Szolgáltató az írásbeli panaszt a beérkezését követően
                harminc napon belül köteles megvizsgálni és érdemben
                megválaszolni továbbá intézkedni arról, hogy a válasz az
                Ügyfélhez eljusson. Ha a panaszt a Szolgáltató elutasítja,
                köteles álláspontját az elutasításra vonatkozó érdemi válaszában
                megindokolni. A panaszról felvett jegyzőkönyvet és a válasz
                másolati példányát a Szolgáltató köteles öt évig megőrizni.
              </p>
            </div>
          </div>
          <div className="aszf-content-container">
            <div className="aszf-headline">
              <h2>Egyéb rendelkezések</h2>
            </div>
            <div className="aszf-content">
              <p>
                8.1. A portfoliotracker.hu weboldal Linux/PHP alapokon működő
                információs rendszer, biztonsági foka megfelelő, használata nem
                jelent kockázatot, azonban javasoljuk, hogy tegye meg az alábbi
                óvintézkedéseket: használjon vírus és spyware védelmi
                szoftvereket friss adatbázissal, telepítse az operációs rendszer
                biztonsági frissítéseit.
              </p>
              <p>
                8.2. A Weboldalon való vásárlás feltételezi az Ügyfél részéről
                az Internet technikai és műszaki korlátainak ismeretét és a
                technológiával együtt járó hibalehetőségek elfogadását.
              </p>
              <p>
                8.3. A Szolgáltató bármikor jogosult jelen ÁSZF feltételeit
                egyoldalúan módosítani.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Aszf;
