import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";
import CookieBanner from "../CookieBanner";
import PermissionDenied from "../PermissionDenied";
import Modal from "../Modal";

import "./Frame.css";

function Frame(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isCookieAccepted = localStorage.getItem("cookieAccepted");
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [underDevelopment, setUnderDevelopment] = useState(false);

  return (
    <div className="view-height">
      <div className="frame-container">
        <DashboardMenu
          setPermissionDenied={setPermissionDenied}
          setUnderDevelopment={setUnderDevelopment}
        />
        {props.children}
      </div>
      <div>{!isCookieAccepted && <CookieBanner />}</div>
      <Footer />
      <PermissionDenied
        onClose={() => setPermissionDenied(false)}
        permissionDenied={permissionDenied}
      />
      <Modal
        onClose={() => setUnderDevelopment(false)}
        isOpenModal={underDevelopment}
        title="Az oldal fejlesztés alatt van"
        description="Weboldalunkat folyamatosan fejlesztjük az újabb funkciókkal, amelyek hamarosan elérhetővé válnak. Kérünk, látogass vissza később. Köszönjük a türelmet!"
        button="Bezár"
      />
    </div>
  );
}

export default Frame;
