import { useCallback } from "react";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";

async function downloadFile(fileName, currentUser) {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(
      `${apiUrl}/api/template/download/${fileName}`,
      {
        headers: {
          Authorization: currentUser.idToken,
        },
      }
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log("error", err);
  }
}

// Hook létrehozása, amely visszaad egy függvényt, amit a komponens használhat
function useDownloadFile() {
  const { getCurrentUser } = useGetCurrentUser();

  const handleDownloadClick = useCallback(async (fileName) => {
    const currentUser = await getCurrentUser();
    downloadFile(fileName, currentUser);
  }, []);

  return { handleDownloadClick };
}

export default useDownloadFile;
