import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase-config";

// export const UseinitializeAuthState = () => {
//   console.log("UseinitializeAuthState start");
//   const { setUser } = useContext(UserContext);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       console.log("UseinitializeAuthState user: ", user);
//       setUser(user);
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   return { loading };
// };
export const initializeAuthState = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
        unsubscribe();
      },
      reject
    );
  });
};
