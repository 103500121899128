import React from "react";
import { Helmet } from "react-helmet";
import "./Login.css";
import Frame from "../components/UI/WebsiteFrame";
import NewPassword from "../components/auth/NewPassword";

function NewPasswordPage() {
  return (
    <Frame>
      <Helmet>
   <title>Jelszó megváltoztatás - Tőzsdézz bátran! - Portfolio Tracker</title>
   <meta name="description"
    content="Jelszó változtatás egyszerűen, gyorsan és biztonságosan."/>
</Helmet>
      <div className="background-frame">
        <div className="login-frame">
          <div className="login-background-container">
            <NewPassword></NewPassword>
          </div>
          <div className="login-background registry"></div>
        </div>
      </div>
    </Frame>
  );
}

export default NewPasswordPage;
