import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../state/userContext";

import "./UserLogin.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase-config";

function UserLogin({ onComponentChange, isCart = false }) {
  const navigate = useNavigate();
  const { setUser, user } = useContext(UserContext);
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [icon, setIcon] = useState(faEyeSlash);
  const [error, setError] = useState("");
  const [isUserSet, setIsUserSet] = useState(false);

  const welcome = isCart ? "Bejelentkezés" : "Üdv újra itt!";

  const hidePasswordHandler = () => {
    if (passwordType === "password") {
      setIcon(faEye);
      setPasswordType("text");
    } else {
      setIcon(faEyeSlash);
      setPasswordType("password");
    }
  };

  const passwordHandleChange = (event) => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);
  };

  const emailHandleChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setError("");
      setUser(result.user);
      setIsUserSet(true);

      // isCart ? onComponentChange("billing") : navigate("/dashboard");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("Rossz jelszó");
      } else if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/invalid-email"
      ) {
        setError("Nem létező email cím");
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isUserSet) {
      isCart ? onComponentChange("billing") : navigate("/dashboard");
    }
  }, [isUserSet]);

  return (
    <div className="login-container">
      <h1>{welcome}</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="email-input">
          <label htmlFor="email" className="input-label">
            Email cím
          </label>
          <div className="reg-elements-container">
            <input
              type="text"
              id="no-border-bottom"
              className="input-column reg"
              value={email}
              onChange={emailHandleChange}
            />
          </div>
        </div>
        <div className="password-input">
          <label htmlFor="password" className="input-label">
            Jelszó
          </label>
          <div className="reg-elements-container">
            <input
              type={passwordType}
              id="no-border-bottom"
              className="input-column reg"
              onChange={passwordHandleChange}
            />
            <FontAwesomeIcon
              icon={icon}
              className="password-hide"
              alt="password-hide"
              onClick={hidePasswordHandler}
            />
          </div>
        </div>
        {error && <span className="invalid">{error}</span>}
        {isCart && (
          <a
            onClick={() => onComponentChange("newPassword")}
            className="new-password"
          >
            Elfelejtetted a jelszavadat?
          </a>
        )}
        {!isCart && (
          <div className="registration new-password">
            <Link to="/elfelejtett-jelszo">Elfelejtetted a jelszavadat?</Link>
          </div>
        )}

        <div className="login-btn-container">
          <button type="submit" className="btn gr-button login-button">
            Bejelentkezés
          </button>
        </div>
        {!isCart && (
          <div className="registration">
            Nincs még felhasználói fiókod?
            <Link to="/regisztracio">Regisztrálj itt</Link>
          </div>
        )}
        {isCart && (
          <div className="registration">
            Nincs még felhasználói fiókod?
            <a
              className="registration-from-cart"
              onClick={() => onComponentChange("register")}
            >
              Regisztrálj itt
            </a>
          </div>
        )}
      </form>
    </div>
  );
}

export default UserLogin;
