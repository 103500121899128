import React from "react";
import "./TableRow.css";
import moment from "moment";

const TableRow = ({
  index,
  data,
  isColumnVisible,
  changeShareId,
  isOpenRow,
  setIsOpenRow,
}) => {
  const detailsLength = data.details.length;

  // Az alsorok felfedését kezelő függvény
  const handleToggle = () => {
    if (data.trigger !== isOpenRow.trigger) {
      setIsOpenRow({ trigger: data.trigger, isOpen: true });
    } else {
      setIsOpenRow({ trigger: data.trigger, isOpen: !isOpenRow.isOpen });
    }
  };

  const clickOnMainItem = () => {
    if (data.details.length === 1) {
      changeShareId(data.details[0].id);
    }
  };

  return (
    <>
      {/* Az összesítő sor */}
      <tr
        key={index}
        className={
          "summary-rows " + (detailsLength === 1 ? "select-cursor" : "")
        }
        onClick={clickOnMainItem}
      >
        <td>
          {detailsLength > 1 && (
            <span className="summary-cursor" onClick={handleToggle}>
              {isOpenRow.isOpen && isOpenRow.trigger === data.trigger
                ? "▼"
                : "►"}
            </span>
          )}
          <span >{data.trigger}</span>
        </td>
        {isColumnVisible && <td className="table-hide">{data.company}</td>}
        {isColumnVisible && <td className="table-hide">{data.sector}</td>}
        {isColumnVisible && <td className="table-hide">{data.shareType}</td>}
        <td>{data.currentPrice?.toFixed(2)}</td>
        <td className="table-hide">{data.purchasePrice?.toFixed(2)}</td>
        <td className="table-hide">{data.currency}</td>
        <td className="table-hide">{data.purchasedShares}</td>
        <td className="table-hide">{data.investedAmount}</td>
        <td className="table-hide">{data.currentValue?.toFixed(2)}</td>
        <td>{data.balance?.toFixed(2)}</td>
        <td className="table-hide">{data.balancePercent?.toFixed(0)} %</td>
        <td className="table-hide">{data.dividentYear?.toFixed(2)}</td>
        <td>{data.myDividentYeald?.toFixed(2)} %</td>
        <td className="table-hide">{data.currentDividentYeald?.toFixed(2)} %</td>
        <td>{data.requirementsMet}</td>
        <td className="table-hide">{moment(data.creationDate).format("YYYY.MM.DD")}</td>
      </tr>
      {/* Az alsorok */}
      {isOpenRow.isOpen &&
        isOpenRow.trigger === data.trigger &&
        detailsLength > 1 && (
          <>
            {data.details.map((detail) => (
              <tr
                key={detail.creationDate}
                className="portfolio-row select-cursor"
                onClick={() => changeShareId(detail.id)}
              >
                <td >{detail.trigger}</td>
                {isColumnVisible && <td className="table-hide">{detail.company}</td>}
                {isColumnVisible && <td className="table-hide">{detail.sector}</td>}
                {isColumnVisible && <td className="table-hide">{detail.shareType}</td>}
                <td>{detail.currentPrice?.toFixed(2)}</td>
                <td className="table-hide">{detail.purchasePrice?.toFixed(2)}</td>
                <td className="table-hide">{detail.currency}</td>
                <td className="table-hide">{detail.purchasedShares}</td>
                <td className="table-hide">{detail.investedAmount}</td>
                <td className="table-hide">{detail.currentValue?.toFixed(2)}</td>
                <td>{detail.balance?.toFixed(2)}</td>
                <td className="table-hide">{detail.balancePercent?.toFixed(0)} %</td>
                <td className="table-hide">{detail.dividentYear?.toFixed(2)}</td>
                <td>{detail.myDividentYeald?.toFixed(2)} %</td>
                <td className="table-hide">{detail.currentDividentYeald?.toFixed(2)} %</td>
                <td>{detail.requirementsMet}</td>
                <td className="table-hide">{moment(detail.creationDate).format("YYYY.MM.DD")}</td>
              </tr>
            ))}
          </>
        )}
    </>
  );
};

export default TableRow;
