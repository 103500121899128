import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import "../../components/auth/UserLogin.css";
import Frame from "../../components/UI/WebsiteFrame";

function PasswordChange() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordBlurred, setPasswordBlurred] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [oobCode, setOobCode] = useState("");
  const [passwordIcon, setPasswordIcon] = useState(faEyeSlash);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(faEyeSlash);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordBlur = () => {
    setPasswordBlurred(true);
    setPasswordValid(isPasswordValid(password));
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    // setPasswordMatch(event.target.value === password);
  };

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordBlurred(true);
    setPasswordMatch(confirmPassword === password);
  };

  const isPasswordValid = (password) => {
    // Password validation logic (minimum 8 characters, contains lowercase, uppercase, and a number)
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const hidePasswordHandler = () => {
    if (passwordType === "password") {
      setPasswordIcon(faEye);
      setPasswordType("text");
    } else {
      setPasswordIcon(faEyeSlash);
      setPasswordType("password");
    }
  };

  const hideConfirmPasswordHandler = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordIcon(faEye);
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordIcon(faEyeSlash);
      setConfirmPasswordType("password");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!oobCode && !password) return;
    try {
      confirmPasswordReset(auth, oobCode, password);
      navigate("/bejelentkezes");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let encodedData = window.location.search;
    if (encodedData) {
      setOobCode(encodedData.match(/oobCode=([^&]*)/)[1]);
    }
  }, []);

  return (
    <Frame>
      <div className="background-frame">
        <div className="login-frame">
          <div className="login-background-container">
            <h1>
              Jelszó <br /> megváltoztatása
            </h1>
            <form onSubmit={handleSubmit} className="login-form">
              <div className="reg-container password-input margin-clear">
                <label className="input-label">Jelszó</label>
                <div className="reg-elements-container">
                  <input
                    type={passwordType}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    className="input-column reg"
                    id="no-border-bottom"
                    required
                  />
                  <FontAwesomeIcon
                    icon={passwordIcon}
                    className="password-hide"
                    alt="password-hide"
                    onClick={hidePasswordHandler}
                  />
                  {passwordBlurred && passwordValid ? (
                    <span className="valid">&#10004;</span>
                  ) : null}
                </div>

                <div className="password-feedback">
                  {passwordBlurred && !passwordValid && (
                    <span className="invalid">
                      A jelszónak minimum 8 karaktert, kis- és nagybetűt,
                      illetve számot kell tartalmaznia.
                    </span>
                  )}
                </div>
              </div>
              <div className="reg-container password-input">
                <label className="input-label">Jelszó megerősítése</label>
                <div className="reg-elements-container">
                  <input
                    type={confirmPasswordType}
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordBlur}
                    className="input-column reg"
                    id="no-border-bottom"
                    required
                  />
                  <FontAwesomeIcon
                    icon={confirmPasswordType}
                    className="password-hide"
                    alt="password-hide"
                    onClick={hideConfirmPasswordHandler}
                  />
                  {passwordMatch &&
                  confirmPasswordBlurred &&
                  password.length > 0 ? (
                    <span className="valid">&#10004;</span>
                  ) : null}
                </div>
                <div className="password-feedback">
                  {!passwordMatch && confirmPasswordBlurred && (
                    <span className="invalid">A jelszó nem egyezik</span>
                  )}
                </div>
              </div>
              <div className="login-btn-container">
                <button type="submit" className="btn gr-button">
                  Mentés
                </button>
              </div>
            </form>
          </div>
          <div className="login-background"></div>
        </div>
      </div>
    </Frame>
  );
}

export default PasswordChange;
