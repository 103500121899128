import React from "react";
import ReactDom from "react-dom";

import "./PermissionDenied.css";

function Modal({ onClose, isOpenModal, title, description, button }) {
  if (!isOpenModal) return null;

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="under-development-modal">
        <div className="modal-button">
          <button onClick={onClose}>X</button>
        </div>
        <h2 className="permission-denied-title">
          {/* Az oldal fejlesztés alatt van */}
          {title}
        </h2>
        <div className="permission-denied-text">
          {/* Fusce condimentum ipsum ac massa lacinia finibus. Maecenas eleifend
          ante lobortis dui dignissim, eu aliquam felis convallis. Vivamus at
          felis tristique, faucibus metus non, imperdiet ex. Curabitur
          tristique, massa vitae sollicitudin tincidunt, augue nunc commodo
          ligula, ac maximus arcu risus eu nisi. Praesent a augue erat. */}
          {description}
        </div>
        <div className="permission-denied-btn">
          <button onClick={onClose} className="btn gr-button cancel">
            {/* Bezár */}
            {button}
          </button>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root")
  );
}

export default Modal;
