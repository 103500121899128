import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Aszf.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function PrivancyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Adatkezelési Tájékoztató - Portfolio Tracker</title>
        <meta
          name="description"
          content="Itt találsz mindent amit az adatkezelésről tudni kell."
        />
      </Helmet>
      <div className="container dark_bg">
        <NavBar />        
        <div className="aszf-container widget-container">
          <div className="aszf-content-container">
            <div className="aszf-headline"></div>
              <div className="aszf-content">
                <h1 className="aszf-title">Adatkezelési Tájékoztató</h1>
              </div>
            </div>
              <div className="aszf-content-container">
                <div className="aszf-headline">
                  <h2>Preambulum</h2>
                </div>
                <div className="aszf-content">
                  <p>Nagyon fontos számunkra a weboldalunk látogatói személyes adatainak védelme, és elkötelezettek vagyunk ennek megfelelő biztosítása mellett. Ez az adatvédelmi nyilatkozat tájékoztatást nyújt arról, hogy hogyan kezeljük az Ön személyes adatait.</p>
                  <p>Jelen Adatkezelési Nyilatkozat célja, hogy a portfoliotracker.hu weboldal felhasználói által, az oldal használata során megadott és a weboldal üzemeltetője (DigitalOcean, LLC, New York, 101 6th Ave, United States) által kezelt személyes adatok és egyéb adatok kezelésére vonatkozó elveket és szabályokat meghatározza.</p>
                  <p>Kapcsolati adatok:</p>
                  <ul>
                    <li>Teljes jogi név: Zvolensky Tibor E.V.</li>
                    <li>E-mail cím: info@portfoliotracker.hu</li>
                    <li>Postai cím: 2112 Veresegyház, Etűd utca 8.</li>
                  </ul>
                </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline">
                  <h2>A személyes adatok gyűjtése</h2>
                </div>
                <div className="aszf-content">
                <p>A következő típusú személyes adatokat gyűjthetjük, tárolhatjuk és felhasználhatjuk:</p>
                <ol type="a">
                  <li>a számítógépével kapcsolatos információkat, beleértve az Ön IP-címét, a földrajzi tartózkodási helyét, a böngészője típusát és verzióját, valamint az operációs rendszerét;</li>
                  <li>információkat a weboldalunk meglátogatásáról és annak használatáról, ideértve a küldő hivatkozás adatait, a látogatás hosszát, az oldalmegtekintéseket és a weboldalon a navigáció adatait;</li>
                  <li>a weboldalunkon a regisztrációkor megadott információkat, például az e-mail címét;</li>
                  <li>a weboldalunkon a profil létrehozásakor megadott információkat — például: a neve, profilképe, neme, születésnapja, kapcsolata állapota, érdeklődési körei és hobbijai, oktatási és foglalkoztatási adatai;</li>
                  <li>a weboldalunkon az e-mailes levelezőlistára és/vagy hírleveleinkre feliratkozáskor megadott információkat, például: a neve, e-mail címe;</li>
                  <li>a weboldalunkon a szolgáltatásaink igénybevételekor megadott információkat;</li>
                  <li>a weboldalunk használata közben keletkező információkat, többek között, hogy mikor, milyen gyakran és milyen körülmények között használja weboldalunkat;</li>
                  <li>az Ön által vásárolt szolgáltatásokkal, az Ön által használt szolgáltatásokkal vagy a weboldalunkon keresztül végzett tranzakciókkal kapcsolatos információkat, többek között: a neve, címe, telefonszáma, e-mail</li>
                  <li>az interneten való közzététel céljából a weboldalunk számára elküldött információkat, többek között: a felhasználóneve, a profilképei és az üzenetei tartalma;</li>
                  <li>az e-mailben vagy a weboldalunkon keresztül az Ön által elküldött információkat, beleértve azok tartalmát és metaadatait;</li>
                  <li>minden egyéb személyes információt, amelyet elküldött számunkra.</li>
                </ol>
                <p>A tevékenységünk során NEM gyűjtünk olyan érzékeny adatokat, mint etnicitás, vallási szemlélet, szexuális élet és orientáció, politikai vélemények és szakszervezeti tagság, vagy egészségügyi háttér, és genetikai vagy biometrikai információk.</p>
                <p>Mielőtt valamely másik személy személyes adatait feltárná előttünk, Önnek kötelező az adott személy hozzájárulásával rendelkeznie a személyes adatok megosztását valamint a személyes adatoknak a jelen adatvédelmi nyilatkozat előírásaival összhangban történő feldolgozását illetően.</p>
                </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Az Ön személyes adatainak felhasználása</h2>
              </div>
              <div className="aszf-content">
                <p>A weboldalunkon keresztül számunkra elküldött személyes adatokat a jelen adatvédelmi nyilatkozatban vagy a weboldal vonatkozó oldalain meghatározott célokra használjuk fel. Az Ön személyes adatait a következő célokra használhatjuk fel:</p>
                <ol typeof="a">
                  <li>a weboldalunk és a vállalkozásunk adminisztrálása;</li>
                  <li>a weboldalunk testre szabása az Ön számára;</li>
                  <li>	a weboldalunkon található szolgáltatások elérhetővé tétele az Ön számára</li>
                  <li>a weboldalunkon keresztül megvásárolt áruk postázása az Ön részére;</li>
                  <li>a weboldalunkon keresztül megvásárolt szolgáltatások nyújtása az Ön részére;</li>
                  <li>számlák és fizetési emlékeztetők küldése, és Öntől a befizetések begyűjtése;</li>
                  <li>nem marketing célú kereskedelmi kommunikáció küldése az Ön részére;</li>
                  <li>olyan e-mail értesítések küldése Önnek, amelyeket Ön kifejezetten kért;</li>
                  <li>e-mail hírlevelünk küldése Önnek, ha azt igényelte (bármikor értesíthet minket, ha már nem kéri a hírlevelet);</li>
                  <li>üzleti vállalkozásunkkal vagy vállalkozásainkkal, illetve gondosan kiválasztott harmadik felek vállalkozásaival kapcsolatos marketingkommunikációk küldése az Ön részére, e-mailben vagy hasonló technológia alkalmazásával (bármikor értesíthet minket, ha már nem kéri a hírlevelet);</li>
                  <li>harmadik felek számára statisztikai információk szolgáltatása felhasználóinkról (azonban ezek a harmadik felek nem azonosíthatnak egyetlen felhasználót sem ezen információk alapján);</li>
                  <li>a weboldalunkkal kapcsolatos, az Ön által vagy az Önről tett tudakozódások és panaszok kezelése;</li>
                  <li>a weboldalunk biztonságának megőrzése és a csalások megelőzése;</li>
                  <li>a weboldalunk használatát szabályozó feltételek betartásának ellenőrzése valamint</li>
                  <li>egyéb felhasználások.</li>
                </ol>
                <p>Ha Ön személyes információkat küld nekünk a weboldalunkon való közzététel céljából, ezeket az információkat mi közzétesszük és egyéb módon felhasználjuk az Ön által nekünk megadott engedély alapján. Az Ön adatvédelmi beállításai korlátozhatják az adataira vonatkozóan a közzétételt a weboldalunkon, ezek a beállítások pedig módosíthatók a weboldalon az adatvédelmi beállítási lehetőségek használatával. Az Ön kifejezett hozzájárulása nélkül nem adjuk át az Ön személyes adatait harmadik feleknek ezen harmadik felek vagy más harmadik felek által folytatott közvetlen marketing céljából.</p>
              </div>              
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Marketing, kommunikáció</h2>
              </div>
              <div className="aszf-content">
                <p>A vállalkozás tevékenysége során nélkülözhetetlen fontosságú marketingkommunikáció folytatása. Az ezzel kapcsolatos adatkezelés jogalapja az érdeklődés mutatása a szolgáltatásaink felé, vagy a felhasználók kifejezett hozzájárulása.</p>
                <p>Az Európai Unió  Privacy and Electronic Communications Regulations (PECR) szabályozása alapján marketingcélú üzeneteket küldünk a felhasználóinknak, ha vásároltak tőlünk, vagy kifejezetten hozzájárultak marketingüzenetek fogadásához.</p>
                <p>A hozzájárulás felfüggesztését, és az üzenetekről való leiratkozást minden esetben jól látható módon lehetővé tesszük. Minden egyes e-mail alján megtalálható a leiratkozáshoz szükséges link, vagy az adatbázisból való eltávolítás kérhető az info@portfoliotracker.hu e-mail címen.</p>
                <p>A marketing kommunikációról való leiratkozás esetén is küldhetünk üzeneteket, de csak a rendelések teljesítésére vonatkozóan.</p>
              </div>         
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>A személyes adatok közzététele</h2>
              </div>
              <div className="aszf-content">
                <p>A személyes adatait bármilyen alkalmazottunkkal, tisztségviselőnkkel, biztosító társasággal, szakmai tanácsadókkal, ügynökökkel, beszállítókkal vagy alvállalkozókkal közölhetjük, amennyiben ez ésszerű és szükséges a jelen adatvédelmi nyilatkozatban meghatározott célok biztosításához.</p>
                <p>A személyes adatait közzé tehetjük:</p>
                <ol type="a">
                  <li>olyan mértékben, amennyire a törvény ezt megköveteli;</li>
                  <li>a folyamatban lévő vagy jövőbeni bírósági eljárásokkal kapcsolatban;</li>
                  <li>a törvényes jogaink megállapítása, gyakorlása vagy védelme érdekében (többek között: a csalások megelőzése céljából); és</li>
                  <li>bármely olyan személy számára, akinek az esetében ésszerűen feltételezhetjük, hogy bírósághoz vagy más illetékes hatósághoz fordulhat a személyes információ közzététele érdekében, amennyiben ésszerű véleményünk szerint az ilyen bíróság vagy hatóság valószínűleg elrendelné az említett személyes információ közzétételét.</li>
                </ol>
                <p>A jelen adatvédelmi nyilatkozatban foglalt esetek kivételével az Ön személyes adatait nem adjuk át harmadik feleknek.</p>
                </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Nemzetközi adattovábbítás</h2>
                </div>
              <div className="aszf-content">
                <p>Az általunk összegyűjtött információkat tárolhatjuk, feldolgozhatjuk és továbbíthatjuk bármely olyan országok között, ahol üzleti tevékenységet folytatunk, annak érdekében, hogy az információkat ezen adatvédelmi nyilatkozatnak megfelelően felhasználhassuk.</p>
                <p>Az általunk összegyűjtött információkat továbbadhatjuk a következő országoknak, amelyek nem rendelkeznek az Európai Gazdasági Térségben hatályos adatvédelmi törvényekkel egyenértékű törvényekkel: Amerikai Egyesült Államok, Japán, Kína és India.</p>
                <p>Az Ön által a weboldalunkon közzétett vagy a weboldalunkon közzétételre az Ön által benyújtott személyes adatok az interneten keresztül elérhetők lehetnek az egész világon. Nem akadályozhatjuk meg az ilyen információk mások általi felhasználását vagy az ilyen információkkal történő visszaélést mások által.</p>
                <p>Ön kifejezetten hozzájárul a személyes adatainak továbbításához a jelen 5. pontban leírtak szerint.</p>
              </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>A személyes adatok megőrzése</h2>
              </div>
              <div className="aszf-content">
                <p>A felhasználók adatait mindig csak addig tároljuk, amíg jogi/könyvelési/adatszolgáltatási kötelezettségeink azt kötelezővé teszik, illetve a szolgáltatás működtetéséhez szükséges.</p>
                <p>Amikor a tárolás időtartamáról döntünk, figyelembe vesszük az adatok mennyiségét, jellegét és érzékenységét, és a kiszivárgásának potenciális hatását adatvédelmi incidens esetén.</p>
                <p>Adózási okokból szükséges megőriznünk a vásárlók számlázási és vásárlási adatait legalább 8 évig, hogy jogi kötelezettségeinkenk eleget tegyünk. </p>
                <p>Bizonyos körülmények között anonimizált formában felhasználhatjuk az adatokat statisztikai célokra, ami esetben tájékoztatás nélkül tároljuk korlátlan ideig az adatokat.</p>
                </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Az Ön személyes adatainak biztonsága</h2>
              </div>
              <div className="aszf-content">
                <p>Számunkra rendkívül fontos a felhasználók adatainak védelme, és a hatályos szabályozásnak való megfelelés. Ezért a portfoliotracker.hu oldalon kiemelt fontosságúként kezeljük az adatvédelmet, és jelentős erőfeszítéseket tettünk az oldal által gyűjtött információk biztonságos gyűjtésére.</p>
                <p>Az űrlapokon megadott és oldalon keletkező adatok védelme érdekében SSL minősítést alkalmazunk a teljes weboldalon (RapidSSL certifikáció).</p>
                <p>Az oldal támadás elleni védelme érdekében prémium biztonsági szoftvert (iThemes Security Pro) alkalmazunk, hogy ú.n. “brute force” és vírusos támadások ellen védjük a tárolt adatokat.</p>
                <p>Jelen adatvédelmi nyilatkozatban űrlapokon keresztül biztosítjuk a felhasználók számára a személyes adataik kezeléséről információ igénylését, a személyes adataik módosítását vagy törlését.</p>
                <p>Időnként az üzleti tevékenységünk érdekében szükségszerű szolgáltató partnereink számára adatokat biztosítani (például tárhelyszolgáltató, futárcég, hírlevélküldő szoftver). </p>
                <p>Ilyen esetben mindig úgy választunk, hogy a GDPR rendelet elvárásainak megfeleljenek, és USA bázisú partner esetében az EU-US Privacy Shield adatvédelmi kezdeményezésben részt vegyenek, valamint adatkezelési szerződést iratunk velük alá, biztosítva az adatok felelős kezelését.</p>
                <p>Az online vásárlás során megadott személyes adatokat a fizetési rendszert biztosító OTP Mobil Kft. kezeli. A bankkártya adatokhoz adatkezelő nem fér hozzá.</p>
                <p>Ön tudomásul veszi, hogy az információ továbbítása az interneten keresztül alapvetően bizonytalan jellegű, és nem garantálhatjuk az interneten keresztül továbbított adatok biztonságát.</p>
                <p>Ön felelős a weboldalunk eléréséhez használt jelszava bizalmas kezeléséért; nem kérjük el a jelszavát (kivéve, amikor bejelentkezik weboldalunkra).</p>
                </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Az Ön jogai</h2>
              </div>
              <div className="aszf-content">
                <p>1. tájékoztatását személyes adatai kezeléséről,</p>
                <p>Felszólíthat minket arra, hogy bocsássunk rendelkezésére minden személyes információt, amely nálunk elérhető Önről; az ilyen információk szolgáltatása a következő feltételek mellett történik:</p>
                <p>Az érintett Felhasználó kérelmére az Adatkezelő tájékoztatást ad az érintett általa kezelt, illetve az általa vagy rendelkezése szerint megbízott adatfeldolgozó által feldolgozott adatairól, azok forrásáról, az adatkezelés céljáról, jogalapjáról, időtartamáról, az adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő tevékenységéről, az adatvédelmi incidens körülményeiről, hatásairól és az elhárítására megtett intézkedésekről, továbbá - az érintett személyes adatainak továbbítása esetén - az adattovábbítás jogalapjáról és címzettjéről.</p>
                <p>Az Adatkezelő a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb 14 napon belül, közérthető formában, az érintett erre irányuló kérelmére írásban megadja a tájékoztatást.</p>
                <p>A tájékoztatás ingyenes, ha a tájékoztatást kérő a folyó évben azonos adatkörre vonatkozó tájékoztatási kérelmet az Adatkezelőhöz még nem nyújtott be. Egyéb esetekben az Adatkezelő költségtérítést állapíthat meg.</p>
                <p>A törvény által megengedett mértékben visszatarthatjuk az Ön által kért személyes információkat.</p>
                <p>2.	személyes adatainak helyesbítését</p>
                <p>Amennyiben a személyes adatok módosultak, vagy tévesen lettek megadva, a felhasználóknak joga van az adatok módosítását kérni. A személyes adatok módosításához kérjük keressen fel minket az info@portfoliotracker.hu e-mail címen.</p>
                <p>3.	személyes adatainak - a kötelező adatkezelés kivételével - törlését vagy zárolását</p>
                <p>A felhasználóknak joga van az összes személyes adatuk törlését kérni. A kérést ingyenesen teljesítjük a kérést követő 14 napon belül. A személyes adatok törlése után a felhasználói fiók nem lesz elérhető, így az esetleg megvásárolt anyagok is elérhetetlenné válnak, mivel a felhasználói fiókhoz kapcsolódó személyes adatok elengedhetetlenül szükségesek a szolgáltatás eléréséhez.</p>
                <p>4.	A személyes adatok feldolgozásának korlátozásának kérése</p>
                <p>A felhasználóknak joga van kérésben korlátozni az adataik rendelkezésére bocsátását harmadik felek (szolgáltató partnerek) számára. A kérés benyújtása során megnevezhetőek a korlátozni kívánt szolgáltató partnerek is.</p>
                <p>Fontos megjegyezni, hogy bizonyos szolgáltatókkal való együttműködés nélkülözhetetlen az oldal működéséhez (pl: Simplepay, mint fizetési szolgáltató), így az ő korlátozásuk esetén az oldal szolgáltatásai elérhetetlenné válnak a felhasználó számára.</p>
                <p>Az portfoliotracker.hu kéri a személyazonosság bizonyítását a személyes adatok átadásának korlátozása előtt, megelőzendő a visszaélésszerű használatot.</p>
              </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Jogorvoslat</h2>
              </div>
              <div className="aszf-content">
                <p>Kérjük a tisztelt Felhasználót, hogy amennyiben úgy érzik, hogy az Adatkezelő megsértette a személyes adatok védelméhez fűződő jogait, akkor vegye fel velünk a kapcsolatot, hogy az esetleges jogsértést orvosolhassuk.</p>
                <p>Tájékoztatjuk a Felhasználót továbbá, hogy az érintett a jogainak megsértése esetén az Adatkezelő ellen bírósághoz fordulhat. A bíróság az ügyben soron kívül jár el. A per elbírálása a törvényszék hatáskörébe tartozik. A per az adatkezelő székhelye szerinti, vagy az érintett választása szerint az érintett lakóhelye vagy tartózkodási helye szerinti törvényszék előtt is megindítható. A perben fél lehet az is, akinek egyébként nincs perbeli jogképessége.</p>
                <p>Ha az Adatkezelő az érintett adatainak jogellenes kezelésével vagy az adatbiztonság követelményeinek megszegésével másnak kárt okoz, köteles azt megtéríteni. Ha az Adatkezelő az érintett adatainak jogellenes kezelésével vagy az adatbiztonság követelményeinek megszegésével az érintett személyiségi jogát megsérti, az érintett az Adatkezelőtől sérelemdíjat követelhet. Az Adatkezelő mentesül az okozott kárért való felelősség és a sérelemdíj megfizetésének kötelezettsége alól, ha bizonyítja, hogy a kárt vagy az érintett személyiségi jogának sérelmét az adatkezelés körén kívül eső elháríthatatlan ok idézte elő. Nem kell megtéríteni a kárt és nem követelhető a sérelemdíj annyiban, amennyiben a kár a károsult vagy a személyiségi jog megsértésével okozott jogsérelem az érintett szándékos vagy súlyosan gondatlan magatartásából származott.</p>
                <p>Az érintett panasszal fordulhat, ill. tájékoztatást kérhet a Hatóságtól is:</p>
                <p>Név: Nemzeti Adatvédelmi és Információs Hatóság</p>
                <p>Székhely: 1125 Budapest Szilágyi Erzsébet fasor 22/c.</p>
                <p>Postacím: 1530 Budapest, Pf.: 5.</p>
                <p>Email: ugyfelszolgalat@naih.hu</p>
                <p>Telefon: +36 (1) 391-1400</p>
                <p>Fax: +36 (1) 391-1410</p>
                <p>Honlap: http://naih.hu</p>
               </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Harmadik felek weboldalai</h2>
              </div>
              <div className="aszf-content">
                <p>Weboldalunk hivatkozásokat és részleteket tartalmaz harmadik felek weboldalait illetően. Nem áll módunkban a harmadik felek adatvédelmi irányelveit és gyakorlatait ellenőrizni, valamint azokért nem vagyunk felelősek.</p>
               </div>
              </div>
              <div className="aszf-content-container">
                <div className="aszf-headline"><h2>Sütik</h2>
              </div>
              <div className="aszf-content">
                <p>Weboldalunk sütiket alkalmaz. A süti egy azonosítót (betűket és számokat) tartalmazó fájlnak felel meg, amelyet a webszerver küld a webböngészőnek, és amelyet a böngésző eltárol. Ezután megtörténik az azonosító visszaküldése a szerverhez minden alkalommal, amikor a böngésző lekér egy adott oldalt a szerverről. A sütik lehetnek „tartós” vagy „munkamenet” sütik: a tartós sütiket a böngésző tárolja, és a megadott lejárati időpontig érvényben maradnak, kivéve, ha a felhasználó a lejárati idő előtt törli őket; a munkamenet süti ezzel szemben lejár a felhasználói munkamenet végén, amikor a böngészőt bezárja a felhasználó. A sütik általában nem tartalmaznak olyan információt, amely által személyesen azonosítható lenne a felhasználó, azonban az Önről általunk tárolt személyes adatok kapcsolódhatnak a sütikben tárolt illetve a sütikből kiolvasható információkhoz. </p>
                <p>Weboldalunkon kizárólag munkamenet sütiket használunk.</p>
                <ol>
                  <li>Az általunk a weboldalunkon alkalmazott sütik nevét, valamint azok felhasználási céljait az alábbiakban ismertetjük:</li>
                  <ul>
                   <li>a weboldalunkon a Google Analytics és az Adwords szoftverek segítségével a számítógép felismerése, amikor egy felhasználó</li> 
                    <ul>
                      <li>meglátogatja a weboldalt </li>
                      <li>a felhasználók nyomon követése, miközben navigálnak a weboldalon</li>
                      <li>a kosár használatának lehetősége a weboldalon</li>
                      <li>a weboldal használhatóságának javítása</li>
                      <li>a weboldal használatának elemzése </li>
                      <li>a weboldal adminisztrálása</li>
                      <li>a csalások megakadályozása és a weboldal biztonságának javítása</li>                    
                    </ul>
                  </ul>
                  <li>A legtöbb böngészőben beállítható a sütik elfogadásának megtagadása — például:</li>
                  <ul>
                    <li>az Internet Explorer böngészőben (10. verziószám) letilthatja a sütiket a sütikezelés felülbírálását érintő beállításokban, ehhez kattintson a következőkre: „Eszközök”, „Internetbeállítások”, „Adatvédelem”, végül pedig a „Speciális” opcióra;</li>
                    <li>A Firefox böngészőben (24. verziószám) az összes süti letiltásához kattintson a következőkre: „Eszközök”, „Opciók”, „Adatvédelem”, ezután a legördülő menüből válassza az „Egyéni beállítások használata előzményekhez” opciót, és törölje a pipát a „Sütik elfogadása” opciónál; valamint</li>
                    <li>A Chrome böngészőben (29. verziószám) az összes süti letiltásához válassza a „Testreszabás és vezérlés” menüt, ezután kattintson a következőkre: „Beállítások”, „Speciális beállítások megjelenítése”, „Tartalombeállítások”, végül válassza ki a „Adatmentés tiltása a webhelyeken” opciót a „Cookie-k” résznél.</li>
                  </ul>
                  <p>Ha letiltja az összes süti használatát, akkor sok weboldal használhatósága romlani fog. A weboldalunkon pedig egyes funkciók nem lesznek elérhetők az Ön számára.</p>
                  <li>Törölheti a számítógépén már tárolt sütiket is — például:</li>
                  <ul>
                    <li>az Internet Explorer böngészőben (10. verziószám) manuálisan kell törölnie a süti-fájlokat (ehhez a http://support.microsoft.com/kb/278835 címen talál leírást);</li>
                    <li>A Firefox böngészőben (24. verziószám) a sütik törléséhez kattintson a következőkre: „Eszközök”, „Opciók”, „Adatvédelem”, ezután válassza az „Egyéni beállításokat használ az előzményekhez” opciót, majd kattintson a „Sütik megjelenítése” opcióra, végül az „Összes eltávolítása” elemre; valamint</li>
                    <li>A Chrome böngészőben (29. verziószám) az összes süti törléséhez válassza a „Testreszabás és vezérlés” menüt, ezután kattintson a következőkre: „Beállítások”, „Speciális beállítások megjelenítése”, „Böngészési adatok törlése”, utóbbinál pedig válassza az „Összes cookie és webhelyadat” lehetőséget, mielőtt a „ Böngészési adatok törlése” elemre kattint.</li>
                  </ul>
                  <li>Ha törli a sütiket, akkor sok weboldal használhatósága romlani fog.</li>
                </ol>
               </div>
              </div>
          </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivancyPolicy;
