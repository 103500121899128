import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, getIdToken } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../state/userContext";

import "../auth/UserLogin.css";

function Registration({ onComponentChange, isCart = false }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const { setUser, user } = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [passwordBlurred, setPasswordBlurred] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [privancyPolicy, setPrivancyPolicy] = useState("");
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(faEyeSlash);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(faEyeSlash);
  const [isUserSet, setIsUserSet] = useState(false);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailAlreadyInUse(false);
  };

  const handleEmailBlur = () => {
    setEmailBlurred(true);
    setEmailValid(validateEmail(email));
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordBlur = () => {
    setPasswordBlurred(true);
    setPasswordValid(isPasswordValid(password));
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    // setPasswordMatch(event.target.value === password);
  };

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordBlurred(true);
    setPasswordMatch(confirmPassword === password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password); // TODO kiszervezni backend-re
      const idToken = await getIdToken(auth.currentUser, true);
      await fetch(`${apiUrl}/api/users/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({
          id: user.user.uid,
          firsName: firstName,
          lastName: lastName,
          email: email,
        }),
      });
      setUser(user.user);
      setIsUserSet(true);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setEmailAlreadyInUse(true);
      }
      console.log("error", error.code);
    }
  };

  useEffect(() => {
    if (isUserSet) {
      isCart ? onComponentChange("billing") : navigate("/dashboard");
    }
  }, [isUserSet]);

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    // Password validation logic (minimum 8 characters, contains lowercase, uppercase, and a number)
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const hidePasswordHandler = () => {
    if (passwordType === "password") {
      setPasswordIcon(faEye);
      setPasswordType("text");
    } else {
      setPasswordIcon(faEyeSlash);
      setPasswordType("password");
    }
  };

  const handlePrivancyPolicyChange = (event) => {
    setPrivancyPolicy(event.target.value);
  };

  const hideConfirmPasswordHandler = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordIcon(faEye);
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordIcon(faEyeSlash);
      setConfirmPasswordType("password");
    }
  };

  return (
    <div className="registration-container">
      <h1>Regisztráció</h1>
      <form onSubmit={handleSubmit}>
        <div className="reg-container name">
          <div className="registration-name">
            <label className="input-label">Vezetéknév</label>
            <input
              type="text"
              onChange={handleLastNameChange}
              className="input-column reg"
              required
            />
          </div>
          <div className="registration-name">
            <label className="input-label">Keresztnév</label>
            <input
              type="text"
              onChange={handleFirstNameChange}
              className="input-column reg"
              required
            />
          </div>
        </div>
        <div className="reg-container email">
          <label className="input-label">Email cím</label>
          <div className="reg-elements-container">
            <input
              type="email"
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              className="input-column reg"
              id="no-border-bottom"
              required
            />
            {emailBlurred && emailValid ? (
              <span className="valid">&#10004;</span>
            ) : null}
          </div>
          <div className="password-feedback">
            {emailBlurred && !emailValid ? (
              <span className="invalid">Invalid email formátum</span>
            ) : null}
            {emailAlreadyInUse && (
              <span className="invalid">E-mail cím már regisztrálva van</span>
            )}
          </div>
        </div>
        <div className="reg-container password-input">
          <label className="input-label">Jelszó</label>
          <div className="reg-elements-container">
            <input
              type={passwordType}
              onChange={handlePasswordChange}
              onBlur={handlePasswordBlur}
              className="input-column reg"
              id="no-border-bottom"
              required
            />
            <FontAwesomeIcon
              icon={passwordIcon}
              className="password-hide"
              alt="password-hide"
              onClick={hidePasswordHandler}
            />

            {passwordBlurred && passwordValid ? (
              <span className="valid">&#10004;</span>
            ) : null}
          </div>
          <div className="password-feedback">
            {passwordBlurred && !passwordValid && (
              <span className="invalid">
                A jelszónak minimum 8 karaktert, kis- és nagybetűt, illetve
                számot kell tartalmaznia.
              </span>
            )}
          </div>
        </div>
        <div className="reg-container password-input">
          <label className="input-label">Jelszó megerősítése</label>
          <div className="reg-elements-container">
            <input
              type={confirmPasswordType}
              onChange={handleConfirmPasswordChange}
              onBlur={handleConfirmPasswordBlur}
              className="input-column reg"
              id="no-border-bottom"
              required
            />
            <FontAwesomeIcon
              icon={confirmPasswordIcon}
              className="password-hide"
              alt="password-hide"
              onClick={hideConfirmPasswordHandler}
            />
            {passwordMatch && confirmPasswordBlurred && password.length > 0 ? (
              <span className="valid">&#10004;</span>
            ) : null}
          </div>
          <div className="password-feedback">
            {!passwordMatch && confirmPasswordBlurred && (
              <span className="invalid">A jelszó nem egyezik</span>
            )}
          </div>
        </div>
        <div className="privancy-policy">
          <input
            type="checkbox"
            id="topping"
            name="privancyPolicy"
            value={privancyPolicy}
            onChange={handlePrivancyPolicyChange}
            required
          />
          <div>
            Az{" "}
            <Link
              to="/aszf"
              className="reg-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adatkezelési Tájékoztatóban
            </Link>{" "}
            foglaltakat megismertem és elfogadtam.
          </div>
        </div>
        <div className="login-btn-container">
          <button type="submit" className="btn gr-button">
            Regisztráció
          </button>
        </div>
      </form>
    </div>
  );
}

export default Registration;
