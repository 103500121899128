import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";

import "./DeleteShare.css";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";

const DeleteShare = ({ open, id, cancelChangeHandler, onClose }) => {
  const { getCurrentUser } = useGetCurrentUser();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [idToken, setIdToken] = useState("");
  const shareId = id;

  const cancelHandler = (event) => {
    event.preventDefault();
    cancelChangeHandler(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserData = await getCurrentUser();
        setIdToken(currentUserData.idToken);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, []);

  async function deleteItem() {
    const response = await fetch(`${apiUrl}/api/portfolio/${shareId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });
    await response.json();
  }

  const deleteHandler = (event) => {
    event.preventDefault();
    deleteItem();
    cancelChangeHandler(false);
    onClose();
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="close-overlay" />
      <div className="close-modal">
        <span>Biztosan törlöd a részvényt?</span>
        <div className="delete-share-button">
          <button onClick={deleteHandler} className="btn remove-button">
            Igen
          </button>
          <button onClick={cancelHandler} className="cancel-button">
            Mégsem
          </button>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root2")
  );
};

export default DeleteShare;
