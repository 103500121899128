import React, { useState, useEffect } from "react";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";
import UserLogin from "../../components/auth/UserLogin";
import NewPassword from "../../components/auth/NewPassword";
import Registration from "../../components/auth/Registration";
import BillingDetails from "../../components/cart/BillingDetails";
import Payment from "../../components/cart/Payment";
import "../../pages/Cart.css";

function CartAuthentication({ product }) {
  const { getCurrentUser } = useGetCurrentUser();

  const [adatok, setAdatok] = useState({});
  const [loading, setLoading] = useState(true);
  const [billingData, setBillingData] = useState({});
  const [activeComponent, setActiveComponent] = useState("");

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  const fetchData = async () => {
    try {
      const response = await getCurrentUser();
      setAdatok(response);
      response?.id
        ? setActiveComponent("billing")
        : setActiveComponent("login");
      setLoading(false);
    } catch (error) {
      setActiveComponent("login");
      console.error("Hiba történt az adatok lekérése során:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setBillingDataHandler = (data) => {
    setBillingData(data);
  };

  return (
    <div className="cart-login">
      {!adatok?.id && (
        <div>
          {activeComponent === "login" && (
            <UserLogin
              onComponentChange={handleComponentChange}
              isCart={true}
            />
          )}
          {activeComponent === "newPassword" && <NewPassword isCart={true} />}
          {activeComponent === "register" && (
            <Registration
              onComponentChange={handleComponentChange}
              isCart={true}
            />
          )}
        </div>
      )}
      {(adatok?.id ||
        activeComponent === "billing" ||
        activeComponent === "payment") && (
        <div>
          {activeComponent === "billing" && (
            <BillingDetails
              onComponentChange={handleComponentChange}
              setBillingDataHandler={setBillingDataHandler}
            ></BillingDetails>
          )}
          {activeComponent === "payment" && (
            <Payment billingData={billingData} product={product}></Payment>
          )}
        </div>
      )}
    </div>
  );
}

export default CartAuthentication;
