import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CookieBanner.css";
import "../App.css";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (isCookieAccepted) {
      setIsVisible(false);
    }
  }, []);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem("cookieAccepted", "true");
  };

  return (
    isVisible && (
      <div className="cookie-banner">
        <div className="cookie-content">
          <p>
          Ez a weboldal sütiket (Cookie) használ a hatékony navigáció és bizonyos funkciók működésének érdekében. Ezen sütik közül a "Szükséges" kategóriába sorolt sütiket az Ön böngészője tárolja, mivel ezek elengedhetetlenek a weboldal alapvető funkcióinak működéséhez.
          </p>
          <button className="btn lg-button sale-button cookie-button" onClick={handleAccept}>
            Elfogadom
          </button>
          <Link to="/adatkezelesi-tajekoztato" className="cookie-information">
            ADATKEZELÉSI <br /> TÁJÉKOZTATÓ
          </Link>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
