import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faArrowUpLong,
  faArrowDownLong,
} from "@fortawesome/free-solid-svg-icons";

import "./Dashboard.css";
import DashboardTable from "../components/dashboard/DashboardTable";
import TopFiveElement from "../components/dashboard/TopFiveElement";
import { useGetCurrentUser } from "../components/auth/userGetCurrentUser";
import Frame from "../components/UI/Frame";

function Dashboard() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getCurrentUser } = useGetCurrentUser();

  const navigate = useNavigate();
  const [topFive, setTopFive] = useState([]);
  const [income, setIncome] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentUser();

        if (!currentUser) {
          navigate("/bejelentkezes");
          return;
        }
        setUser(currentUser);

        const data = await fetch(
          `${apiUrl}/api/dashboard/totalvalue/${currentUser.id}`,
          {
            headers: {
              Authorization: currentUser.idToken,
            },
          }
        );
        const result = await data.json();
        setIncome(result);
        setTopFive(result.topFive);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const controlPanel = [
    {
      title: "Portfólióm",
      details: "Megvásárolt részvények strukturált vezetése.",
      img: "chartPie",
      link: "/portfolio",
    },
    {
      title: "Statisztika",
      details: "Portfólió statisztika, stratégiai megfeleltetés.",
      img: "squarePollVertical",
      link: "/dashboard",
    },
    {
      title: "Figyelőlista",
      details: "A kiszemelt nagyszerű vállalatok gyűjtőhelye.",
      img: "crosshairs",
      link: "/",
    },
    {
      title: "Elemzések",
      details: "Vállalatok elemzése.",
      img: "chartLine",
      link: "/",
      close: "Lock",
    },
    {
      title: "Stratégia",
      details: "Stratégia meghatározása, küszöbértékek megadása.",
      img: "chessKnight",
      link: "/",
    },
    {
      title: "Sablonok",
      details: "Értékszám, beszámoló  elemzés, versenytárs elemzés.",
      img: "fileLines",
      link: "/sablonok",
    },
    {
      title: "Könyv",
      details: "A Tőzsdézz bátran című könyv letöltése.",
      img: "bookOpen",
      link: "/konyv-vasarlas",
    },
  ];

  const controlPanelItems = controlPanel.map((item) => (
    <DashboardTable
      img={item.img}
      title={item.title}
      details={item.details}
      key={item.title}
      link={item.link}
      close={item.close}
      currentUser={user}
    />
  ));

  const listItems =
    topFive &&
    topFive.map((item) => <TopFiveElement item={item} key={item.company} />);

  <Helmet>
    <title>Dashboard - Tőzsdézz bátran! - Portfolio Tracker</title>
    <meta
      name="description"
      content="Minden egy helyen. Portfólió követés, figyelőlista, elemzések, sablonok."
    />
  </Helmet>;

  return (
    <Frame>
      <div className="top-five">
        <FontAwesomeIcon icon={faCircleUser} className="avatar" />
        <span className="user-name">
          {user?.last_name} {user?.first_name}
        </span>
        <span className="amount">
          <NumericFormat
            value={income.sumCurrentPrice}
            thousandSeparator=" "
            displayType="text"
            suffix={" HUF"}
          />
        </span>
        <span
          className={
            "difference " +
            (income.lastDayResult > 0
              ? "increase"
              : income.lastDayResult < 0
              ? "decrease"
              : "no-changes")
          }
        >
          {income.lastDayResult > 0 && (
            <FontAwesomeIcon className="increase" icon={faArrowUpLong} />
          )}
          {income.lastDayResult < 0 && (
            <FontAwesomeIcon className="decrease" icon={faArrowDownLong} />
          )}
          <NumericFormat
            value={
              income.lastDayResult > 0
                ? income.lastDayResult
                : income.lastDayResult * -1
            }
            thousandSeparator=" "
            displayType="text"
            suffix={" HUF"}
          />
          <NumericFormat
            value={
              income.lastDayResultDiff > 0
                ? income.lastDayResultDiff
                : income.lastDayResultDiff * -1
            }
            decimalScale={3}
            displayType="text"
            suffix={"%)"}
            prefix={" ("}
          />
        </span>
        <div className="top-five-items">{listItems}</div>
      </div>
      <div className="dashboard">
        <div className="income">
          <h2 className="dashboard-title">Bevétel</h2>
          <div className="income-container">
            <div className="income-item">
              <span className="income-period">Ma</span>
              <span
                className={
                  "income-value " +
                  (income.lastDayResult > 0 ? "increase" : "decrease")
                }
              >
                <NumericFormat
                  value={income.lastDayResult}
                  thousandSeparator=" "
                  displayType="text"
                  suffix={" HUF"}
                />
              </span>
            </div>
            <div className="income-item">
              <span className="income-period">Héten</span>
              <span
                className={
                  "income-value " +
                  (income.lastWeekResult > 0 ? "increase" : "decrease")
                }
              >
                <NumericFormat
                  value={income.lastWeekResult}
                  thousandSeparator=" "
                  displayType="text"
                  suffix={" HUF"}
                />
              </span>
            </div>
            <div className="income-item">
              <span className="income-period">Hónapban</span>
              <span
                className={
                  "income-value " +
                  (income.lastMonthResult > 0 ? "increase" : "decrease")
                }
              >
                <NumericFormat
                  value={income.lastMonthResult}
                  thousandSeparator=" "
                  displayType="text"
                  suffix={" HUF"}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="control-panel">
          <h2 className="dashboard-title">Irányítópult</h2>
          <div className="dashboard-items">{controlPanelItems}</div>
        </div>
      </div>
    </Frame>
  );
}

export default Dashboard;
