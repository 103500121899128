import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import { useGetCurrentUser } from "../../components/auth/userGetCurrentUser";

function PaymentResult({
  onClose,
  paymentResult,
  paymentResultText,
  transactionId,
}) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getCurrentUser } = useGetCurrentUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (paymentResultText === "SUCCESS") {
      localStorage.removeItem("SET_PRODUCT");
    }
  }, []);

  if (!paymentResult) return null;

  const fetchTransaction = async (apiUrl, orderId, token) => {
    const response = await fetch(`${apiUrl}/api/payment/${orderId}`, {
      headers: {
        Authorization: token,
      },
    });
    return await response.json();
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    const currentUser = await getCurrentUser();
    let attempts = 0;
    let transactionResult = null;
    const orderId = 10000033;

    while (attempts < 5) {
      transactionResult = await fetchTransaction(
        apiUrl,
        orderId,
        currentUser.idToken
      );
      console.log("transactionResult: ", transactionResult);

      if (transactionResult?.finish_date) {
        break;
      }
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
    setLoading(false);
    navigate("/dashboard");
  };

  const SpinnerOverlay = () => (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="under-development-modal">
        {loading && <SpinnerOverlay />}
        <div className="modal-button">
          <button onClick={onClose}>X</button>
        </div>
        <div>
          {paymentResultText === "SUCCESS" && (
            <div>
              <h2 className="permission-denied-title">Sikeres tranzakció</h2>
              <p className="permission-denied-text">
                SimplePay tranzakció azonosító: {transactionId}
              </p>
            </div>
          )}
          {paymentResultText === "FAIL" && (
            <div>
              <h2 className="permission-denied-title">Sikertelen tranzakció</h2>
              <p>SimplePay tranzakció azonosító: {transactionId}</p>
              <p>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedjen
                kapcsolatba lépni kártyakibocsátó bankjával.
              </p>
            </div>
          )}
          {paymentResultText === "TIMEOUT" && <p>Időtúllépés</p>}
          {paymentResultText === "CANCEL" && <p>Megszakított fizetés</p>}
        </div>
        <div className="permission-denied-btn">
          <Link>
            <button className="btn gr-button" onClick={handleClick}>
              OK
            </button>
          </Link>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root")
  );
}

export default PaymentResult;
