import React from "react";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpLong,
  faArrowDownLong,
} from "@fortawesome/free-solid-svg-icons";

import increaseSign from "../../assets/increase-sign.png";
import decreaseSign from "../../assets/decrease-sign.png";

function TopFiveElement({ item }) {
  return (
    <div className="top-five-container" key={item.company}>
      <span className="company-name">{item.company}</span>
      <div className="top-five-details">
        <span className="top-five-amount">
          <NumericFormat
            value={item.currentPrice}
            thousandSeparator=" "
            displayType="text"
            suffix={" " + item.currency}
          />
        </span>
        <div className="top-five-change ">
          {item.difference > 0 && (
            <FontAwesomeIcon className="increase" icon={faArrowUpLong} />
          )}
          {item.difference < 0 && (
            <FontAwesomeIcon className="decrease" icon={faArrowDownLong} />
          )}
          <span
            className={
              "top-five-difference " +
              (item.difference > 0
                ? "increase"
                : item.difference < 0
                ? "decrease"
                : "no-changes")
            }
          >
            <NumericFormat
              value={
                item.lastDayResult > 0
                  ? item.lastDayResult
                  : item.lastDayResult * -1
              }
              thousandSeparator=" "
              decimalScale={3}
              displayType="text"
              suffix={" " + item.currency}
            />
            <NumericFormat
              value={
                item.difference > 0 ? item.difference : item.difference * -1
              }
              thousandSeparator=" "
              displayType="text"
              decimalScale={3}
              suffix={"%)"}
              prefix={"("}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default TopFiveElement;
