import React from "react";
import "./Tables.css";

import chess from "../assets/tables-chess.png";
import increase from "../assets/tables-increase.png";
import pie_chart from "../assets/tables-pie-chart.png";
import watchlist from "../assets/tables-watchlist.png";

function Tables({ color, title }) {
  return (
    <>

        <div className="table-first">
          <div className={`table ${color}`} id="strategia-table" >
            <img src={chess} alt="Stratégia" className="homepage_img" />
            <div className="table-content">
              <span className={title}>Stratégia</span>
              <section>
                Stratégia meghatározása, küszöbértékek megadása
              </section>
            </div>
          </div>
          <div className={`table ${color}`} id="elemzesek-table" >
            <img src={increase} alt="Elemzések" className="homepage_img" />
            <div className="table-content">
              <span className={title}>Elemzések</span>
              <section>
                Vállalatok elemzése
              </section>
            </div>
          </div>
        </div>

        <div className="table-second">
          <div className={`table ${color}`} id="portfolio-table" >
            <img src={pie_chart} alt="Portólióm" className="homepage_img" />
            <div className="table-content">
              <span className={title}>Portfolióm</span>
              <section>
                Megvásárolt részvények strukturált vezetése
              </section>
            </div>
          </div>
          <div className={`table ${color}`} id="konyvvezetes-table" >
            <img src={watchlist} alt="Könyvvezetés" className="homepage_img" />
            <div className="table-content">
              <span className={title}>Figyelőlista</span>
              <section>
                A kiszemelt nagyszerű vállalatok gyűjtőhelye
              </section>
            </div>
          </div>
        </div>

    </>
  );
}

export default Tables;
