import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./Login.css";
import Frame from "../components/UI/WebsiteFrame";
import UserLogin from "../components/auth/UserLogin";
import NewPassword from "../components/auth/NewPassword";

function Login() {
  const [activeComponent, setActiveComponent] = useState("");

  const handleComponentChange = () => {
    setActiveComponent("newPassword");

  };

  return (
    <Frame>
        <Helmet>
  <title>Bejelentkezés - Tőzsdézz bátran! - Portfolio Tracker</title>
  <meta
    name="description"
    content="Portfólió menedzsment, Figyelő lista, Vállalat elemzések, Stratégia, Statisztika, Értékelő sablonok."
  />
</Helmet>
      <div className="background-frame">
        <div className="login-frame">
          <div className="login-background-container">
            {activeComponent !== "newPassword" && (
              <UserLogin onComponentChange={handleComponentChange}></UserLogin>
            )}
            {activeComponent === "newPassword" && <NewPassword></NewPassword>}
          </div>
          <div className="login-background"></div>
        </div>
      </div>
    </Frame>
  );
}

export default Login;
